import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { Sizes } from '../internal/types';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks';
import './StatusIndicator.scss';
import {
  AnchorManager,
  AnchorManagerProps,
} from '../internal/components/AnchorManager';

export type StatusIndicatorStatus = 'online' | 'away' | 'error' | 'offline';

export interface StatusIndicatorProps
  extends Omit<AnchorManagerProps, 'badgeElement'>,
    CommonProps {
  /**
   * Specify the status to show from the following list:
   */
  status?: StatusIndicatorStatus;
  /**
   * Specify the size of the status indicator.
   */
  size?: Sizes;
}

export const StatusIndicator = forwardRef<HTMLDivElement, StatusIndicatorProps>(
  (
    {
      children,
      status = 'online',
      size = 'md',
      placement = 'bottom-end',
      overlap = 'circular',
      className,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const indicatorElement = (
      <div className={clsx([`${cssPrefix}-statusindicator`, status, size])} />
    );

    return (
      <AnchorManager
        {...rest}
        ref={ref}
        id={id}
        overlap={overlap}
        badgeElement={indicatorElement}
        placement={placement}
      >
        {children}
      </AnchorManager>
    );
  }
);
