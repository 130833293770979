import {
  DateTime,
  Interval,
  Info,
  DateTimeFormatOptions,
  DateTimeUnit,
  DurationUnit,
} from 'luxon';

const { fromJSDate, fromFormat, now } = DateTime;

/**
 * Checks if the locale string format is valid.
 * If the locale is not recognized, it will fallback to the runtime's default locale.
 * @returns a boolean
 */
export const getValidLocale = (locale: string) => {
  let valid = true;

  try {
    // @ts-ignore
    Intl.getCanonicalLocales(locale);
  } catch (error) {
    valid = false;
  }

  return valid ? locale : 'en-US';
};

/**
 * Gets current date at start of day
 * @returns a Date
 */
export const getCurrentDate = () => DateTime.now().startOf('day').toJSDate();

/**
 * Checks if date is valid
 * @returns a boolean
 */
export const dateIsValid = (date: Date | null) =>
  !!date && fromJSDate(date).isValid;

/**
 * Gets date format for specific locale
 * @returns a string
 */
export const getDatePattern = (locale: string) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

  const formatter = new Intl.DateTimeFormat(
    locale,
    options as DateTimeFormatOptions
  ).formatToParts();

  return formatter
    .map((e) => {
      switch (e.type) {
        case 'month':
          return 'MM';
        case 'day':
          return 'dd';
        case 'year':
          return 'yyyy';
        default:
          return e.value;
      }
    })
    .join('');
};

/**
 * Parses string to date while accounting for locale format
 * @returns a Date
 */
export const parseDate = (date: string, localeName: string) =>
  fromFormat(date, getDatePattern(localeName), {
    locale: localeName,
  }).toJSDate();

/**
 * Converts a date into a local time string
 * @returns the local time string
 */
export const dateToTimeString = (date: Date | null, locale: string) => {
  return !!date && dateIsValid(date)
    ? fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE, { locale })
    : '';
};

/**
 * Converts a local time string into a date
 * @returns a date
 */
export const timeStringToDate = (timeStr: string, locale: string) => {
  // Note 't' is a shorthand provided by `luxon` for a localized time.
  return DateTime.fromFormat(timeStr, 't', { locale }).toJSDate();
};

/**
 * Gets times to display in popover time list
 * @returns an array
 */
export const getTimeFragments = (step: number) => {
  const date = new Date();
  const startOfDay = fromJSDate(date).startOf('day').toJSDate();
  const endOfDay = fromJSDate(date).endOf('day').toJSDate();

  const intervalsInDay = Interval.fromDateTimes(startOfDay, endOfDay)
    .splitBy({ minutes: step && step > 0 ? step : 15 })
    .map((d) => d.start!.toJSDate());

  return intervalsInDay;
};

/**
 * Compares 2 date times regardless of year, month, or date
 * @returns true if the times are equal
 */
export function isTimeEqual(
  a: Date | null | undefined,
  b: Date | null | undefined
): boolean {
  if (!a || !b) return false;

  return (
    a.getHours() === b.getHours() &&
    a.getMinutes() === b.getMinutes() &&
    a.getSeconds() === b.getSeconds() &&
    a.getMilliseconds() === b.getMilliseconds()
  );
}

/**
 * @returns Invalid Date string as Date Object
 */
export const getInvalidDate = () =>
  fromJSDate('Invalid Date' as unknown as Date).toJSDate();

/**
 * Checks to see if two dates are same
 * @returns a boolean
 */
export const isSame = (
  date1: Date | null | undefined,
  date2: Date | null | undefined,
  comparator: DateTimeUnit
) => {
  return (
    !!date1 &&
    !!date2 &&
    fromJSDate(date1).hasSame(fromJSDate(date2), comparator)
  );
};

/**
 * Adds time to date
 * @returns a boolean
 */
export const addTime = (date: Date, timeUnit: DurationUnit, amount: number) =>
  fromJSDate(date)
    .plus({ [timeUnit]: amount })
    .toJSDate();

/**
 * Subtracts time from date
 * @returns a boolean
 */
export const subtractTime = (
  date: Date,
  timeUnit: DurationUnit,
  amount: number
) =>
  fromJSDate(date)
    .minus({ [timeUnit]: amount })
    .toJSDate();

/**
 * Gets unit of time (day, month, or year, etc) from date
 * @returns a string
 */
export const getUnit = (date: Date, timeUnit: keyof DateTime) =>
  fromJSDate(date).get(timeUnit);

/**
 * Checks if date is today
 * @returns a boolean
 */
export const isToday = (date: Date) => now().hasSame(fromJSDate(date), 'day');

/**
 * Formats selected date in locale's respective format
 * @returns a string
 */
export const formatDate = (date: Date | null, localeName: string) => {
  return !!date && fromJSDate(date).isValid
    ? fromJSDate(date)
        .setLocale(localeName)
        .toLocaleString({ day: '2-digit', month: '2-digit', year: 'numeric' })
    : '';
};

/**
 * Gets days to display for month in popover calendar
 * @returns an array
 */
export const getDaysInMonth = (navigatedDate: Date): Date[] => {
  const beginningOfMonth = getFirstDayOfMonth(navigatedDate);

  const endOfMonth = fromJSDate(navigatedDate).endOf('month').toJSDate();
  const endOfMonthWeekday = fromJSDate(endOfMonth).weekday;
  const dayIsSunday = fromJSDate(beginningOfMonth).weekday === 7;
  const lastDaySaturday = endOfMonthWeekday === 6;
  const lastDaySunday = endOfMonthWeekday === 7;

  const startDate =
    // check if day is Sunday
    dayIsSunday
      ? beginningOfMonth
      : // if day is not sunday go to previous sunday
        fromJSDate(beginningOfMonth)
          .minus({ weeks: 1 })
          .set({ weekday: 7 })
          .toJSDate();

  const endDate = lastDaySaturday
    ? endOfMonth
    : fromJSDate(endOfMonth)
        // if day is sunday, add a week
        .plus({ weeks: lastDaySunday ? 1 : 0 })
        .set({ weekday: 6 })
        .toJSDate();

  const daysInMonth = Interval.fromDateTimes(startDate, endDate)
    .splitBy({ days: 1 })
    .map((d) => d.start.toJSDate());

  return daysInMonth;
};

/**
 * Returns first day of month
 * @returns a date
 */
export const getFirstDayOfMonth = (navigatedDate: Date) =>
  fromJSDate(navigatedDate).startOf('month').toJSDate();

/**
 * Returns last day of month
 * @returns a date
 */
export const getLastDayOfMonth = (navigatedDate: Date) =>
  fromJSDate(navigatedDate).endOf('month').startOf('day').toJSDate();
/**
 * Returns placeholder for calendar input
 * @returns a string
 */
export const getPlaceholder = (localeName: string) =>
  getDatePattern(localeName).toLowerCase();

/**
 * Returns heading text for calendar months
 * @returns a string
 */
export const getHeadingText = (navigatedDate: Date, locale: string) =>
  fromJSDate(navigatedDate).setLocale(locale).toFormat('MMMM yyyy');

/**
 * Returns heading text abbreviations for weekday names
 * @returns a string
 */
export const getWeekdayNames = (localeName: string) => {
  const weekdays = Info.weekdays('narrow', { locale: localeName });
  // since Luxon weekdays start on Monday, we correct this for our calendar
  weekdays.unshift(weekdays.pop()!);
  return weekdays;
};
