import { useCallback, useEffect, useRef, useState } from 'react';
import { AsyncStatus } from '../enums';
import { Searcher, SearcherOnSearch, SearcherOptions } from '../utils/Searcher';

const defaultErrorHandler = (error: any) => {
  console.error(`CDS: ${error}`);
};

interface UseSearcherState<TResult> {
  canLoadMore: boolean;
  error: any;
  results: TResult[];
  status: AsyncStatus;
}

export interface UseSearcher<TResult, TCursor>
  extends UseSearcherState<TResult> {
  reset: () => void;
  search: (query: string) => void;
  searchDebounced: (query: string) => void;
  searcher: Searcher<TResult, TCursor>;
  tryLoadMore: () => void;
}

// Hook to use a searcher. Expects to be passed a function that does the api call and returns results.
export function useSearcher<TResult, TCursor>(
  /**
   * This will be called when we need to search.
   */
  onSearch: SearcherOnSearch<TResult, TCursor>,
  /**
   * You can optionally handle any search errors, such as to log them to a metrics platform. By default,
   * they're passed to `console.error`.
   */
  onError?: (error: any) => void,
  options?: SearcherOptions
): UseSearcher<TResult, TCursor> {
  // Create a searcher if we don't have one.
  const searcherRef = useRef<Searcher<TResult, TCursor>>();
  if (!searcherRef.current) {
    searcherRef.current = new Searcher<TResult, TCursor>(onSearch, options);
  }
  const searcher = searcherRef.current;
  const errorHandler = onError || defaultErrorHandler;

  // Update the callback so it doesn't get stale
  searcher.onSearch = onSearch;

  const [, setRenderCount] = useState(0);

  function forceRerender() {
    setRenderCount((count) => {
      return count + 1;
    });
  }

  // Mark ourself as "dead" when we unmount so that we don't call any methods.
  const isDeadRef = useRef(false);
  useEffect(() => {
    return () => {
      isDeadRef.current = true;
    };
  }, []);

  // -------------- Searcher Methods --------------
  // The Searcher is class based, so in order to trigger rerenders when its properties change,
  // we're manually rerendering.

  const tryLoadMore = useCallback(function tryLoadMore() {
    // This returns undefined if we can't load more.
    const p = searcher.tryLoadMore();
    if (p) {
      p.catch(errorHandler).finally(forceRerender);
      forceRerender();
    }
  }, []);

  const reset = useCallback(function reset() {
    searcher.reset();
    forceRerender();
  }, []);

  const search = useCallback(function search(query: string) {
    searcher.search(query).catch(errorHandler).finally(forceRerender);

    forceRerender();
  }, []);

  const searchDebounced = useCallback(function searchDebounced(query: string) {
    searcher.searchDebounced(query).catch(errorHandler).finally(forceRerender);

    forceRerender();
  }, []);

  return {
    canLoadMore: searcher.canLoadMore,
    error: searcher.error,
    results: searcher.results,
    status: searcher.status,
    reset,
    search,
    searchDebounced,
    searcher,
    tryLoadMore,
  };
}
