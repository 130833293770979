import React, { forwardRef, useRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CSSTransition } from 'react-transition-group';
import { CommonProps } from '../internal/interfaces';
import { Colors } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks';
import './Badge.scss';
import {
  AnchorManager,
  AnchorManagerProps,
} from '../internal/components/AnchorManager';

export interface BadgeProps
  extends Omit<AnchorManagerProps, 'badgeElement'>,
    CommonProps {
  /**
   * Specify the color of the badge
   */
  color?: Colors;
  /**
   * Specify if badge has border or not
   */
  bordered?: boolean;
  /**
   * Specify the content of the badge
   */
  content?: number | string;
  /**
   * Specify the max number displayed in badge content
   */
  max?: number;
  /**
   * Specify if the badge is hidden or not
   */
  hide?: boolean;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      children,
      color = 'primary',
      bordered,
      placement = 'top-end',
      overlap = 'default',
      hide = false,
      max = 99,
      content,
      className,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const nodeRef = useRef(null);
    const id = useId(idProp);
    const contentIsString = typeof content === 'string';

    const getContent = (badgeContent?: string | number): string | null => {
      if (!badgeContent) return null;
      if (typeof badgeContent === 'number') {
        if (badgeContent > max) return `${max.toLocaleString('en-US')}+`;
        return `${badgeContent.toLocaleString('en-US')}`;
      }
      return badgeContent;
    };

    const badgeElement = (
      <CSSTransition
        in={!hide}
        timeout={225}
        classNames={`${cssPrefix}-badge-animation`}
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div
          className={clsx([
            `${cssPrefix}-badge`,
            color,
            bordered && 'bordered',
          ])}
          ref={nodeRef}
          aria-hidden
        >
          <div
            className={clsx(['badge-content', contentIsString && 'maxWidth'])}
          >
            {getContent(content)}
          </div>
        </div>
      </CSSTransition>
    );

    return (
      <AnchorManager
        {...rest}
        ref={ref}
        id={id}
        placement={placement}
        overlap={overlap}
        badgeElement={badgeElement}
      >
        {children}
      </AnchorManager>
    );
  }
);
