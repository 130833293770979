import React, { createContext, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CSSTransition } from 'react-transition-group';
import { TabLoop } from '../internal/components/TabLoop';
import { Portal } from '../internal/components/Portal';
import { Key, AnimState } from '../internal/enums';
import { useCSSPrefix, useScrollManager } from '../internal/hooks';
import { CommonProps } from '../internal/interfaces';
import { Surface, SurfaceElevation } from '../Surface';
import './Drawer.scss';

export type DrawerVariant = 'embedded' | 'temporary';
export type DrawerAnchor = 'left' | 'right';
export type DrawerWidth = 'narrow' | 'wide';

export const DRAWER_WIDTH_WIDE = '536px';
export const DRAWER_WIDTH_NARROW = '320px';

export interface IDrawerContext {
  anchor: DrawerAnchor;
}

export const DrawerContext = createContext<IDrawerContext | null>(null);

export interface DrawerProps
  extends React.ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify the variant to use.
   * @default 'temporary'
   */
  variant?: DrawerVariant;
  /**
   * Specify the side from which the drawer will appear.
   * @default 'right'
   */
  anchor?: DrawerAnchor;
  /**
   * Specify the width of the drawer.
   * @default 'narrow'
   */
  width?: DrawerWidth;
  /**
   * Specify the elevation of the drawer.
   * @default 4
   */
  elevation?: SurfaceElevation;
  /**
   * If `true`, the backdrop is not rendered.
   * @default false
   */
  hideBackdrop?: boolean;
  /**
   * If `true`, the close callback will not be fired when pressing Esc key.
   * @default false
   */
  disableEscapeKeyDown?: boolean;
  /**
   * If `true`, the close callback will not be fired when clicking the backdrop.
   * @default false
   */
  disableBackdropClick?: boolean;
  /**
   * Specify the contents of the drawer.
   */
  content?: React.ReactNode;
  /**
   * If `variant = 'embedded'` then the component will need to wrap the contents of the page.
   */
  children?: React.ReactNode;
  /**
   * If `true`, the drawer is shown
   */
  open: boolean;
  /**
   * Specify the callback to be fired when the component requests to be closed.
   */
  onClose?: () => void;
  /**
   * Callback fired as the Drawer transitions in.
   */
  onEnter?: () => void;
  /**
   * Callback fired right before the Drawer transitions out.
   */
  onExit?: () => void;
}

export const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      variant = 'temporary',
      anchor = 'right',
      width = 'narrow',
      elevation = 4,
      hideBackdrop = false,
      disableEscapeKeyDown = false,
      disableBackdropClick = false,
      content,
      children,
      open,
      onClose,
      className,
      role,
      onEnter,
      onExit,
      id: idProp,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const isTemporaryVariant = variant === 'temporary';
    const isAnchorLeft = anchor === 'left';

    // removes CSSTransition findDOMNode error in StrictMode
    const nodeRefPanel = useRef(null);
    const nodeRefBackdrop = useRef(null);
    const drawerPanelRef = useRef<HTMLDivElement>(null);
    const [animState, setAnimState] = useState(AnimState.Hidden);

    useScrollManager(
      open,
      animState,
      drawerPanelRef.current,
      undefined,
      !isTemporaryVariant
    );

    const inputKeyDown = (e: React.KeyboardEvent) =>
      e.target instanceof HTMLInputElement;

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.defaultPrevented) return;

      if (e.key === Key.Space && !inputKeyDown(e)) {
        e.preventDefault();
      }

      if (e.key === Key.Escape) {
        e.preventDefault();
        e.stopPropagation();
        !disableEscapeKeyDown && onClose?.();
      }
    };

    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.defaultPrevented) return;

      !disableBackdropClick && onClose?.();
    };

    function handleOnEnter() {
      setAnimState(AnimState.Enter);
      onEnter && onEnter();
    }

    function handleOnExit() {
      setAnimState(AnimState.Hidden);
      onExit && onExit();
    }

    // Helper function that wraps the incoming JSX with the following html syntax
    const renderRoot = (rootChildren: React.ReactChild) => (
      <div
        {...props}
        ref={ref}
        id={id}
        className={clsx([
          {
            [`${cssPrefix}-drawer`]: !isTemporaryVariant,
            [`drawer-width-${width}`]: !isTemporaryVariant,
          },
          className,
        ])}
        role={role || 'presentation'}
        onKeyDown={isTemporaryVariant ? handleKeyDown : undefined}
      >
        {rootChildren}
      </div>
    );

    /**
     * In order to maintain z-index stacking context (and now
     * that we're rendering the Drawer in a portal), the structure
     * of the drawer root needs to be conditionally rendered to support
     * the embedded variant. This will optionally add a transition to the panel
     * inside the drawerRoot
     */
    function getDrawerRoot(showTransition: boolean = false) {
      const panel = (
        <Surface
          className={clsx(
            `${cssPrefix}-drawer-panel`,
            `drawer-width-${width}`,
            `drawer-anchor-${anchor}`
          )}
          variant="default"
          radii="none"
          elevation={elevation}
          ref={nodeRefPanel}
        >
          <TabLoop innerContainerRef={drawerPanelRef}>
            <div
              className={`${cssPrefix}-drawer-panel-content`}
              ref={drawerPanelRef}
            >
              {content}
            </div>
          </TabLoop>
        </Surface>
      );
      if (!showTransition) return renderRoot(panel);
      return renderRoot(
        <CSSTransition
          in={open}
          timeout={225}
          classNames={`${cssPrefix}-drawer-panel-anim-${width}-${anchor}`}
          unmountOnExit
          nodeRef={nodeRefPanel}
          onEnter={handleOnEnter}
          onExit={handleOnExit}
        >
          {panel}
        </CSSTransition>
      );
    }

    const temporaryDrawerRoot = (
      <>
        <CSSTransition
          in={open}
          timeout={225}
          classNames={`${cssPrefix}-drawer-backdrop-anim`}
          unmountOnExit
          nodeRef={nodeRefBackdrop}
        >
          <div
            aria-hidden
            tabIndex={-1}
            className={clsx(
              `${cssPrefix}-drawer-backdrop`,
              !hideBackdrop && 'visible'
            )}
            onClick={handleBackdropClick}
            onKeyDown={handleKeyDown}
            ref={nodeRefBackdrop}
          />
        </CSSTransition>
        <CSSTransition
          in={open}
          timeout={225}
          classNames={`${cssPrefix}-drawer-panel-anim-${width}-${anchor}`}
          unmountOnExit
          nodeRef={nodeRefPanel}
          onEnter={handleOnEnter}
          onExit={handleOnExit}
        >
          {getDrawerRoot()}
        </CSSTransition>
      </>
    );

    const value = useMemo(
      () => ({
        anchor,
      }),
      [anchor]
    );

    if (isTemporaryVariant)
      return (
        <Portal>
          <DrawerContext.Provider value={value}>
            {temporaryDrawerRoot}
          </DrawerContext.Provider>
        </Portal>
      );

    const drawerWidth =
      width === 'narrow' ? DRAWER_WIDTH_NARROW : DRAWER_WIDTH_WIDE;

    const pageContainer = (
      <div
        style={{
          transition: 'margin 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          [isAnchorLeft ? 'marginLeft' : 'marginRight']: open ? drawerWidth : 0,
        }}
      >
        {children}
      </div>
    );

    return (
      <DrawerContext.Provider value={value}>
        <div className={`${cssPrefix}-drawer-wrap`}>
          {!isAnchorLeft && pageContainer}
          {getDrawerRoot(true)}
          {isAnchorLeft && pageContainer}
        </div>
      </DrawerContext.Provider>
    );
  }
);
