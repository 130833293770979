import React, { ComponentPropsWithRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { Typography } from '../Typography';
import { Colors, SeverityColors } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks';
import './ProgressBar.scss';
import { clampNumber } from '../internal/utils/clamp';
import { CommonProps } from '../internal/interfaces';

export type ProgressBarColor = SeverityColors | Colors;
export type ProgressBarDirection = 'ltr' | 'rtl';
export interface ProgressBarProps
  extends ComponentPropsWithRef<'div'>,
    CommonProps {
  /**
   * Specify the position of the label text within the progress bar
   * @default 'left'
   */
  alignLabel?: 'left' | 'right' | 'center';
  /**
   * Enable this prop to animate the progress bar background
   * @default false
   */
  animated?: boolean;
  /**
   * Specify the progress bar color
   * @default 'info'
   */
  color?: ProgressBarColor;
  /**
   * Enable this prop to render a 'skinny' progress bar
   * @default false
   */
  compact?: boolean;
  /**
   * Specify the direction the progress bar is moving
   * @default 'ltr'
   */
  direction?: ProgressBarDirection;
  /**
   * Specify a label to show within the progress bar
   */
  label?: string;
  /**
   * Specify a value associated with the progress
   */
  value: number;
}

export const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      alignLabel = 'left',
      animated = false,
      className,
      color = 'info',
      compact = false,
      direction = 'ltr',
      label,
      style,
      value,
      id: idProp,
      ...rest
    }: ProgressBarProps,
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const MIN_VALUE = 0;
    const MAX_VALUE = 100;
    const percentage = clampNumber(value, MIN_VALUE, MAX_VALUE);

    return (
      <div
        className={clsx([
          `${cssPrefix}-progress-bar-wrapper`,
          animated && `progress-bar-animated`,
          compact && `progress-bar-compact`,
          `progress-bar-${direction}`,
        ])}
        ref={ref}
        id={id}
      >
        <div
          {...rest}
          aria-valuenow={percentage}
          aria-valuemin={MIN_VALUE}
          aria-valuemax={MAX_VALUE}
          className={clsx([
            `${cssPrefix}-progress-bar`,
            `progress-bar-${color}`,
            className,
          ])}
          role="progressbar"
          style={{ width: `${percentage}%`, ...style }}
        >
          {!compact && (
            <Typography
              // mdn says browser applies 'presentation' role to content within `role="progressbar"`, but it doesn't appear to do it automatically
              role="presentation"
              variant="body2"
              fontWeight="medium"
              color="tertiary"
              className={clsx([
                `progress-bar-align-label-${alignLabel}`,
                color === 'warning' && 'progress-bar-warning-typography',
              ])}
            >
              {label}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);
