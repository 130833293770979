import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { MoreSizes } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks';
import { BrandIconMap, BrandIconType } from './icon-map';
import './BrandIcon.scss';

export interface BrandIconProps
  extends ComponentPropsWithoutRef<'svg'>,
    CommonProps {
  /**
   * Specify the icon to render from the following list:
   */
  icon: BrandIconType;
  /**
   * By default the size will be inherited from the current font size. Optionally, specify a size from the following list:
   */
  size?: MoreSizes;
}

export const BrandIcon = forwardRef<SVGSVGElement, BrandIconProps>(
  ({ icon, className, size, id: idProp, ...props }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const Comp: React.VFC<React.SVGProps<SVGSVGElement>> = BrandIconMap[icon];

    return (
      <Comp
        {...props}
        ref={ref}
        id={id}
        className={clsx([
          `${cssPrefix}-brand-icon`,
          size && `${cssPrefix}-brand-icon-${size}`,
          className,
        ])}
      />
    );
  }
);
