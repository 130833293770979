import React, { useCallback, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { HelperText } from '../internal/components/HelperText';
import {
  useControlledState,
  useCSSPrefix,
  useDescriptiveText,
} from '../internal/hooks';
import { CommonProps, InputFieldProps } from '../internal/interfaces';
import './RadioButton.scss';

export interface RadioGroupProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>,
    InputFieldProps {
  /**
   * Specifies the name of the `input` element
   */
  name?: string;
  /**
   * If controlled, specifies the value of an `input` element
   */
  value?: string | number;
  /**
   * If uncontrolled, specifies a default selected value within the Radio Group
   */
  defaultValue?: string | number;
  /**
   * Specifies either a `horizontal` or `vertical` orientation for the Radio Group
   * @default horizontal
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * If controlled, specify a function to be called when the Radio Group `value` changes
   */
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: number | string
  ) => void;
}

export interface RadioButtonProps
  extends React.ComponentPropsWithoutRef<'input'>,
    CommonProps {
  /**
   * Specifies the value of an `input` element
   */
  value: string | number;
  /**
   * Specify whether the Radio Button should be disabled, or not
   * @default false
   */
  disabled?: boolean;
  /**
   * Specifies the text to the right of the Radio Button
   */
  label?: string;
}

interface RadioGroupContextType {
  name?: string;
  value?: string | number;
  selectedValue?: string;
  onRadioChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedInputValue: string | number
  ) => void;
}

const RadioGroupContext = React.createContext<RadioGroupContextType>(
  {} as RadioGroupContextType
);

export const RadioGroup = React.forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      value: valueProp,
      defaultValue,
      onChange,
      name,
      label,
      orientation = 'horizontal',
      helperText,
      successText,
      errorText,
      children,
      className,
      id: idProp,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useControlledState(
      defaultValue,
      valueProp,
      'RadioButton'
    );

    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const { status } = useDescriptiveText(errorText, successText, helperText);

    const handleRadioChange = useCallback(
      (
        e: React.ChangeEvent<HTMLInputElement>,
        selectedInputValue: string | number
      ) => {
        if (valueProp === undefined) {
          setValue(selectedInputValue);
        }
        onChange?.(e, selectedInputValue);
      },
      []
    );

    const context = useMemo(
      () => ({ name, value, onRadioChange: handleRadioChange }),
      [name, value, handleRadioChange]
    );

    return (
      <div
        {...props}
        ref={ref}
        id={id}
        className={clsx([
          `${cssPrefix}-radiogroup`,
          orientation,
          className,
          status,
        ])}
      >
        <fieldset className={`${cssPrefix}-radiogroup-fieldset`}>
          {!!label && (
            <legend className={`${cssPrefix}-radiogroup-label`}>{label}</legend>
          )}
          <RadioGroupContext.Provider value={context}>
            {children}
          </RadioGroupContext.Provider>
        </fieldset>
        <HelperText
          errorText={errorText}
          successText={successText}
          helperText={helperText}
        />
      </div>
    );
  }
);

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      value,
      label,
      disabled,
      'data-testid': dataTestId,
      id: idProp,
      style,
      className,
      ...props
    },
    ref
  ) => {
    const {
      name,
      value: selectedValue,
      onRadioChange,
    } = useContext(RadioGroupContext);

    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const checked = value === selectedValue;

    return (
      <div
        id={id}
        data-testid={dataTestId}
        style={style}
        className={clsx([`${cssPrefix}-radiobutton-wrapper`, className])}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className={clsx([
            `${cssPrefix}-radiobutton-label`,
            disabled && 'disabled',
          ])}
        >
          <div
            className={clsx([
              `${cssPrefix}-radiobutton`,
              'radio',
              checked && 'checked',
              disabled && 'disabled',
            ])}
          >
            <div className={`${cssPrefix}-radiobutton-inner-circle`}>
              <input
                {...props}
                name={name}
                type="radio"
                value={value}
                onChange={(e) => onRadioChange(e, value)}
                checked={checked}
                className={`${cssPrefix}-radiobutton-input`}
                tabIndex={disabled ? -1 : 0}
                disabled={disabled}
                ref={ref}
              />
            </div>
          </div>
          {label}
        </label>
      </div>
    );
  }
);
