import { withGlobalValue } from './withGlobalValue';

const browserLanguage =
  (typeof navigator !== 'undefined' && navigator.language) || 'en-US';

// We're casting so that we get better autocomplete for the returned variable names.

// Returns the current locale and a function that can be used to set the global locale.
export const useLocale = withGlobalValue<string>(browserLanguage) as (
  overrideLocale?: string
) => [locale: string, setGlobalLocale: (newLocale: string) => void];
