import React, { createContext, forwardRef, useMemo, useContext } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { MoreSizes, MoreColors, Colors } from '../internal/types';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks';
import { IconButtonProps } from '../IconButton';
import { TopNavigationContext } from '../TopNavigation';
import { SideNavigationContext } from '../SideNavigation';
import './IconButtonGroup.scss';

export interface IIconButtonGroupContext {
  color: MoreColors;
  size: MoreSizes;
  disabled: boolean;
}

export const IconButtonGroupContext =
  createContext<IIconButtonGroupContext | null>(null);

export type IconButtonGroupOrientation = 'horizontal' | 'vertical';

export interface IconButtonGroupProps
  extends Pick<IconButtonProps, 'color' | 'children' | 'size'>,
    CommonProps {
  /**
   * Optionally specify if the Icon Button Group should take up the full width of it's container
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Specify the orientation of the Icon Button Group
   */
  orientation?: IconButtonGroupOrientation;
  /**
   * Specify whether the Icon Button Group should be disabled, or not
   *
   * @default false
   */
  disabled?: boolean;
}

function getColorToUse(incomingColor?: Colors): MoreColors {
  if (incomingColor === 'secondary') return 'tertiary';
  return 'default';
}

export const IconButtonGroup = forwardRef<HTMLDivElement, IconButtonGroupProps>(
  (
    {
      orientation = 'horizontal',
      color,
      disabled = false,
      size = 'md',
      fullWidth = false,
      className,
      children,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const { color: topNavColor } = useContext(TopNavigationContext) || {};
    const { color: sideNavColor } = useContext(SideNavigationContext) || {};

    const colorToUse = color ?? getColorToUse(topNavColor || sideNavColor);

    const value = useMemo(
      () => ({
        color: colorToUse,
        size,
        disabled,
      }),
      [color, size, disabled]
    );

    return (
      <IconButtonGroupContext.Provider value={value}>
        <div
          {...rest}
          ref={ref}
          id={id}
          className={clsx([
            className,
            `${cssPrefix}-icon-buttongroup`,
            orientation,
            fullWidth && 'fullwidth',
          ])}
          role="group"
        >
          {children}
        </div>
      </IconButtonGroupContext.Provider>
    );
  }
);
