import React, { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { Colors } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks';
import { CommonProps } from '../internal/interfaces';
import { Collapse } from '../internal/components/Collapse';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import StepperContext, { useStepperContext } from './StepperContext';
import './Stepper.scss';

enum StepStatus {
  Remaining = 'remaining',
  Active = 'active',
  Completed = 'completed',
}

type StepStatusType = { status: StepStatus };
type StepperColorType = Pick<StepperProps, 'color'>;
type StepConnectorProps = StepperColorType & StepStatusType;

const StepConnector = ({ color, status }: StepConnectorProps) => {
  const [cssPrefix] = useCSSPrefix();
  return (
    <div
      className={clsx([
        `${cssPrefix}-step-connector`,
        status !== StepStatus.Remaining && color,
      ])}
    />
  );
};

type StepIconProps = StepperColorType &
  StepStatusType &
  Pick<StepProps, 'index'>;

const StepIcon = ({ status, index = 0, color = 'primary' }: StepIconProps) => {
  const [cssPrefix] = useCSSPrefix();

  const showCheck = status === StepStatus.Completed;

  return (
    <div
      className={clsx([`${cssPrefix}-step-icon-container`, status, color])}
      aria-disabled={status === StepStatus.Remaining}
    >
      {showCheck ? (
        <Icon icon="success-filled" />
      ) : (
        <Typography variant="body1" className={`${cssPrefix}-step-icon-number`}>
          {index + 1}
        </Typography>
      )}
    </div>
  );
};

type StepLabelProps = StepStatusType & Pick<StepProps, 'label'>;

const StepLabel = ({ status, label }: StepLabelProps) => {
  const [cssPrefix] = useCSSPrefix();

  const disabled = status === StepStatus.Remaining;

  return (
    <div className={`${cssPrefix}-step-label-container`}>
      <Typography
        className={`${cssPrefix}-step-label`}
        variant="body1"
        fontWeight="bold"
        disabled={disabled}
        aria-disabled={disabled}
      >
        {label}
      </Typography>
    </div>
  );
};

function getStepStatus(active: number, index: number): StepStatus {
  if (active === index) return StepStatus.Active;
  if (active > index) return StepStatus.Completed;
  return StepStatus.Remaining;
}

export interface StepperProps
  extends React.ComponentPropsWithRef<'div'>,
    CommonProps {
  /**
   * The currently active step (zero based index). Set to -1 to disable all steps.
   */
  activeStep?: number;
  /**
   * The color theme to apply.
   */
  color?: Colors;
  /**
   * The orientation the steps should be displayed.
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * The content of the component.
   */
  children: React.ReactNode;
}

export const Stepper = forwardRef<HTMLDivElement, StepperProps>(
  (
    {
      className,
      'data-testid': dataTestId,
      activeStep = 0,
      color = 'primary',
      orientation = 'horizontal',
      children,
      id: idProp,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const contextValue = useMemo(
      () => ({
        color,
        activeStep,
        orientation,
      }),
      [color, activeStep, orientation]
    );

    const childrenLength = React.Children.count(children);

    return (
      <StepperContext.Provider value={contextValue}>
        <div
          {...props}
          ref={ref}
          id={id}
          className={clsx([
            `${cssPrefix}-stepper-root`,
            `${cssPrefix}-stepper-${orientation}`,
            className,
          ])}
          data-testid={dataTestId}
        >
          {React.Children.map(children, (child, index) => {
            // Ignore child if its not a Step
            if (!React.isValidElement<StepProps>(child)) return child;

            const status = getStepStatus(activeStep, index);
            const isFirst = index === 0;
            const isVertical = orientation === 'vertical';

            return (
              <>
                {isVertical && !isFirst && (
                  <StepConnector status={status} color={color} />
                )}
                {React.cloneElement(child, {
                  ...child.props,
                  index,
                  last: index === childrenLength - 1,
                })}
              </>
            );
          })}
        </div>
      </StepperContext.Provider>
    );
  }
);

export interface StepProps
  extends React.ComponentPropsWithRef<'div'>,
    CommonProps {
  /**
   * The label for the step
   */
  label?: string;
  /**
   * The position of the step. The prop defaults to the value inherited from the parent Stepper component.
   */
  index?: number;
  /**
   * If `true`, is the last rendered Step. The prop defaults to the value inherited from the parent Stepper component.
   */
  last?: boolean;
  /**
   * The content of the step
   */
  children?: React.ReactNode;
}

export const Step = forwardRef<HTMLDivElement, StepProps>(
  (
    {
      className,
      'data-testid': dataTestId,
      label,
      index = 0,
      last,
      children,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const { color, activeStep = 0, orientation } = useStepperContext() || {};
    const status = getStepStatus(activeStep, index);

    const isFirst = index === 0;
    const isVertical = orientation === 'vertical';

    return (
      <div
        {...props}
        ref={ref}
        className={clsx([`${cssPrefix}-step-root`, className])}
        data-testid={dataTestId}
      >
        {!isVertical && !isFirst && (
          <StepConnector status={status} color={color} />
        )}

        <div className={`${cssPrefix}-step`}>
          <StepIcon color={color} index={index} status={status} />
          <StepLabel label={label} status={status} />
        </div>

        {children && (
          <div
            className={clsx([
              `${cssPrefix}-step-content`,
              isVertical && !last && 'border',
              isVertical && status === StepStatus.Completed && color,
            ])}
          >
            {isVertical ? (
              <Collapse in={status === StepStatus.Active} unmountOnExit>
                {children}
              </Collapse>
            ) : (
              children
            )}
          </div>
        )}
      </div>
    );
  }
);
