import React, {
  ReactNode,
  FC,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  useContext,
  useMemo,
  createContext,
} from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { useMediaQuery } from '../useMediaQuery';
import { useBreakpoints } from '../useBreakpoints';
import './LocalNavigation.scss';

export type PositionType =
  | 'absolute'
  | 'fixed'
  | 'relative'
  | 'static'
  | 'sticky';
export interface LocalNavigationProps
  extends ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Provide an optional heading for the Local Navigation. The heading is responsive and will change font size when screen size is changed.
   */
  heading?: string;
  /**
   * Provide an optional function to be fired when the 'back' button is clicked
   */
  onBackClick?: () => void;
  /**
   * Provide an optional sub text to be placed under the heading
   */
  subtext?: string;
  /**
   * Specify how nav element scrolls with the page
   */
  position?: PositionType;
  /**
   * Specify the content of Local Navigation. The two children options for this component are `LocalNavigationActions` and `LocalNavigationFooter`.
   */
  children?: ReactNode;
}

export interface LocalNavigationContextProps {
  isMediumOrHigher: boolean;
}

export const LocalNavContext =
  createContext<LocalNavigationContextProps | null>(null);

export const LocalNavigation = React.forwardRef<
  HTMLDivElement,
  LocalNavigationProps
>(
  (
    {
      children,
      onBackClick,
      heading,
      subtext,
      position = 'static',
      className,
      id: idProp,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const breakpoints = useBreakpoints();
    const isMediumOrHigher = useMediaQuery(breakpoints.up('md'));
    const isLargeOrHigher = useMediaQuery(breakpoints.up('lg'));

    const showBackButton = !!onBackClick;
    const absoluteOrFixed = position === 'absolute' || position === 'fixed';

    const value = useMemo(() => ({ isMediumOrHigher }), [isMediumOrHigher]);

    const filler = (
      <div
        className={clsx([
          `${cssPrefix}-local-nav-filler`,
          {
            'local-nav-filler-large': isLargeOrHigher,
          },
        ])}
      />
    );

    return (
      <LocalNavContext.Provider value={value}>
        <div
          {...props}
          ref={ref}
          id={id}
          className={clsx([
            `${cssPrefix}-local-navigation`,
            `local-navigation-position-${position}`,
            { 'padding-medium': isMediumOrHigher },
            className,
          ])}
        >
          <div className="local-navigation-header">
            {showBackButton && (
              <IconButton
                size={isMediumOrHigher ? 'md' : 'sm'}
                color="secondary"
                className={clsx(['local-navigation-icon-btn'], {
                  'adjust-alignment': !!subtext,
                })}
                aria-label="back"
                onClick={onBackClick}
              >
                <Icon icon="chevron-left" />
              </IconButton>
            )}
            <div
              className={clsx([
                'local-navigation-header-content',
                { 'adjust-margin': showBackButton },
              ])}
            >
              {heading && (
                <Typography
                  variant="h2"
                  className={clsx([
                    'local-navigation-heading',
                    {
                      'font-medium': isMediumOrHigher,
                      'font-large': isLargeOrHigher,
                    },
                  ])}
                >
                  {heading}
                </Typography>
              )}
              {subtext && (
                <Typography
                  variant="caption1"
                  className="local-navigation-subtext"
                >
                  {subtext}
                </Typography>
              )}
            </div>
          </div>
          {children}
        </div>
        {absoluteOrFixed && filler}
      </LocalNavContext.Provider>
    );
  }
);

export const LocalNavigationActions: FC<{ children: ReactNode }> = ({
  children,
}: ComponentPropsWithRef<'div'>) => {
  const [cssPrefix] = useCSSPrefix();
  const { isMediumOrHigher } = useContext(LocalNavContext) || {};

  return (
    <div className={`${cssPrefix}-local-navigation-actions`}>
      {isMediumOrHigher ? (
        <div className="local-navigation-actions-children">{children}</div>
      ) : (
        <IconButton
          color="default"
          size="sm"
          className="local-navigation-actions-icon-btn"
          aria-label="Open menu"
        >
          <Icon icon="actions-vertical" />
        </IconButton>
      )}
    </div>
  );
};

export const LocalNavigationFooter: FC<{ children: ReactNode }> = ({
  children,
}: ComponentPropsWithRef<'div'>) => {
  const [cssPrefix] = useCSSPrefix();
  const { isMediumOrHigher } = useContext(LocalNavContext) || {};

  return (
    <div
      className={clsx([
        `${cssPrefix}-local-navigation-footer`,
        { 'margin-offset-medium': isMediumOrHigher },
      ])}
    >
      <div className="local-navigation-footer-container">{children}</div>
    </div>
  );
};
