/**
 * The 'clamp' function takes 3 values. Num, min and max. If num is in the range between min and max it returns num.
 * If num is lower than min it returns min and if num is higher than max it returns max.
 *
 * @param num
 * @param min
 * @param max
 * @returns
 */
export const clampNumber = (num: number, min: number, max: number) =>
  Math.max(min, Math.min(max, num));
