// @ts-nocheck
/* eslint-disable @typescript-eslint/dot-notation */
/**
 * Obtains the browser's locale code, otherwise defaults to `en-US`.
 */
export function getBrowserLocaleCode() {
  if (typeof navigator === 'undefined') return null;
  return (
    navigator.languages?.[0] ||
    navigator['userLanguage'] ||
    navigator.language ||
    navigator['browserLanguage'] ||
    'en-US'
  );
}
