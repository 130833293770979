import React, { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { CommonProps } from '../../interfaces';
import { useCSSPrefix } from '../../hooks';
import './AnchorManager.scss';

export type AnchorManagerOverlap = 'default' | 'circular';

export type AnchorManagerPlacement =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end';

export interface AnchorManagerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    CommonProps {
  /**
   * Optionally, specify a placement from the following list:
   * @default 'top-end'
   */
  placement?: AnchorManagerPlacement;
  /**
   * Specify the content of the anchor manager.
   */
  badgeElement?: ReactNode;
  /**
   * Specify the children of the component.
   */
  children?: ReactNode;
  /**
   * Places the component relative to the corner of the wrapped element
   */
  overlap?: AnchorManagerOverlap;
}

export const AnchorManager = forwardRef<HTMLDivElement, AnchorManagerProps>(
  (
    {
      children,
      placement = 'top-end',
      overlap = 'default',
      badgeElement,
      className,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();

    return (
      <div
        {...rest}
        ref={ref}
        className={clsx([`${cssPrefix}-anchormanager-root`, className])}
      >
        <div className={`${cssPrefix}-anchormanager-content`}>
          {children}
          <div
            className={clsx([`${cssPrefix}-anchormanager`, placement, overlap])}
          >
            {badgeElement}
          </div>
        </div>
      </div>
    );
  }
);
