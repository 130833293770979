import React, { ElementType } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import './Breadcrumbs.scss';
import { Link } from '../Link';
import { Typography } from '../Typography';
import { Icon } from '../Icon/Icon';
import {
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuItem,
  PopoverMenuToggleButton,
} from '../PopoverMenu';
import { IconButton } from '../IconButton';
import { clampNumber } from '../internal/utils/clamp';

export interface BreadcrumbProps {
  /**
   * The label prop represents the text displayed in an individual breadcrumb
   */
  label: string;
  /**
   * Provide an optional function to be called when the breadcrumb element is clicked
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optionally specify a link's destination. If an onClick prop is also passed in, that will take precedence over this
   */
  href?: string;
  /**
   * Specify the React Element to render as the underlying DOM element.
   */
  as?: ElementType;
  /**
   * If the React Element specified is not a standard HTML element (i.e. React Router Link),
   * expose 'to' prop available to navigate to a specified route.
   */
  to?: string;
}

export interface BreadcrumbsProps
  extends React.ComponentPropsWithoutRef<'nav'>,
    CommonProps {
  /**
   * Specify an array of objects of type BreadcrumbProps to be rendered
   */
  breadcrumbs: BreadcrumbProps[];
  /**
   * Specifies the maximum number of breadcrumbs to display. When there are more than the maximum number, only the first itemsBeforeCollapse and last itemsAfterCollapse will be shown, with an ellipsis in between.
   * @default 6
   */
  maxItems?: number;
  /**
   * If max items is exceeded, the number of items to show before the ellipsis.
   * @default 1
   */
  itemsBeforeCollapse?: number;
  /**
   * If max items is exceeded, the number of items to show after the ellipsis.
   * @default 1
   */
  itemsAfterCollapse?: number;
  /**
   * If `true`, applies a negative margin to offset the spacing of the first breadcrumb.
   * @default false
   */
  overrideMarginLeft?: boolean;
}

const getAsComponent = (
  hasButtonCondition: boolean,
  hasLinkCondition: boolean,
  fallback: any
) => {
  if (hasButtonCondition) return 'button';
  if (hasLinkCondition) return 'a';
  return fallback;
};

export const Breadcrumbs = React.forwardRef<HTMLElement, BreadcrumbsProps>(
  (
    {
      breadcrumbs,
      maxItems = 6,
      itemsBeforeCollapse = 1,
      itemsAfterCollapse = 1,
      'data-testid': dataTestId,
      className,
      id: idProp,
      overrideMarginLeft,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const listLength = breadcrumbs.length - 1;

    // '.slicing' our breadcrumbs array creates new arrays. we need the index of an item in the original list.
    const listIndex = (label: string) =>
      breadcrumbs.findIndex((item) => item.label === label);

    // 'before and after' prop needs min / max
    const beforeRange = clampNumber(
      itemsBeforeCollapse,
      1,
      listLength - clampNumber(itemsAfterCollapse, 1, listLength)
    );

    const afterRange = clampNumber(
      itemsAfterCollapse,
      1,
      listLength - clampNumber(itemsBeforeCollapse, 1, listLength)
    );

    const breadcrumbsBefore = breadcrumbs.slice(0, beforeRange);
    const breadcrumbsAfter = breadcrumbs.slice(
      -(afterRange + 1),
      breadcrumbs.length
    );
    const breadcrumbsInMenu = breadcrumbs.slice(
      beforeRange,
      breadcrumbs.length - (afterRange + 1)
    );

    const renderBreadcrumbLinks = ({
      label,
      onClick,
      href,
      as,
      to,
    }: BreadcrumbProps) => {
      const isLink = typeof as === 'object' || href || onClick;
      const asComponent = getAsComponent(!!onClick, !!href, as);

      return (
        <li
          key={listIndex(label)}
          className="breadcrumb-list-item"
          data-testid={`${dataTestId}-${listIndex(label)}-${label}`}
        >
          {isLink ? (
            <Link
              variant="standalone"
              as={asComponent}
              {...(typeof as === 'object' && { to })}
              onClick={onClick}
              href={href}
              className={clsx([
                'breadcrumb-label',
                overrideMarginLeft && 'override-margin-left',
              ])}
            >
              {label}
            </Link>
          ) : (
            <Typography
              className={clsx([
                'breadcrumb-label',
                overrideMarginLeft && 'override-margin-left',
              ])}
            >
              {label}
            </Typography>
          )}
          {listIndex(label) !== breadcrumbs.length - 1 && (
            <Icon
              className="breadcrumb-icon"
              aria-hidden="true"
              icon="chevron-right"
            />
          )}
        </li>
      );
    };

    return (
      <nav
        {...rest}
        ref={ref}
        id={id}
        data-testid={dataTestId}
        className={clsx([`${cssPrefix}-breadcrumbs`, className])}
        aria-label={rest['aria-label'] || 'Breadcrumb'}
      >
        <ol className="breadcrumb-list">
          {breadcrumbs.length > maxItems && breadcrumbs.length !== 1 ? (
            <>
              {/* before (left) */}
              {breadcrumbsBefore.map(renderBreadcrumbLinks)}

              {/* menu (center) */}
              {breadcrumbsInMenu.length >= 1 && (
                <li>
                  <PopoverMenu maxHeight="172px">
                    <div>
                      <PopoverMenuToggleButton
                        as={IconButton}
                        aria-label="show collapsed breadcrumbs"
                        size="xs"
                      >
                        <Icon icon="actions-horizontal" />
                      </PopoverMenuToggleButton>

                      <Icon
                        className="breadcrumb-icon"
                        aria-hidden="true"
                        icon="chevron-right"
                      />
                    </div>

                    <PopoverMenuContent>
                      {breadcrumbsInMenu.map(
                        ({ label, onClick, href, as, to }, index) => {
                          const asComponent = getAsComponent(
                            !!onClick,
                            !!href,
                            as
                          );

                          return (
                            <PopoverMenuItem
                              id={listIndex(label).toString()}
                              as={asComponent}
                              key={index}
                              {...(typeof as === 'object' && { to })}
                              href={href}
                              onClick={onClick}
                            >
                              {label}
                            </PopoverMenuItem>
                          );
                        }
                      )}
                    </PopoverMenuContent>
                  </PopoverMenu>
                </li>
              )}

              {/* after (right) */}
              {breadcrumbsAfter.map(renderBreadcrumbLinks)}
            </>
          ) : (
            // max length greater than breadcrumbs.length
            breadcrumbs.map(renderBreadcrumbLinks)
          )}
        </ol>
      </nav>
    );
  }
);
