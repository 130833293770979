import React, { useRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CSSTransition } from 'react-transition-group';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix, useForwardedRef } from '../internal/hooks';
import { Icon, IconType } from '../Icon';
import { IconButton } from '../IconButton';
import './BannerMessage.scss';
import { SeverityColors } from '../internal/types';

export type BannerMessageSeverity = Exclude<SeverityColors, 'success'>;

const variantIconMap: { [key in BannerMessageSeverity]: IconType } = {
  info: 'info-filled',
  warning: 'warning-filled',
  error: 'error-filled',
};

export interface BannerMessageProps
  extends React.ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify the severity to show, from the following list of variants:
   */
  severity: BannerMessageSeverity;
  /**
   * Each severity variant comes with a default icon. Optionally, specify an icon to override the default for the severity variant. Or set to `false` to disable the icon. Available icon overrides include:
   */
  icon?: IconType | boolean;
  /**
   * Specify if the banner is visible or not.
   */
  show: boolean;
  /**
   * Optionally, specify a function to be called when the close button is clicked. If not present, the close button will be hidden and the banner cannot be dismissed.
   */
  onClose?: () => void;
}

export const BannerMessage = React.forwardRef<
  HTMLDivElement,
  BannerMessageProps
>(
  (
    {
      children,
      severity,
      icon,
      show,
      onClose,
      className,
      role = 'alert',
      id: idProp,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    // removes CSSTransition findDOMNode error in StrictMode
    const nodeRef = useRef(null);

    return (
      <CSSTransition
        in={show}
        timeout={300}
        classNames={`${cssPrefix}-banner`}
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div
          {...props}
          ref={useForwardedRef(nodeRef, ref)}
          id={id}
          className={clsx([className, `${cssPrefix}-banner-${severity}`])}
          role={role}
        >
          <div className={`${cssPrefix}-banner-message`}>
            <div className="banner-message-inner">
              <span className="banner-content">
                {icon === false ? null : (
                  <Icon
                    size="xs"
                    icon={
                      typeof icon === 'boolean' || typeof icon === 'undefined'
                        ? variantIconMap[severity]
                        : icon
                    }
                  />
                )}
                {children}
              </span>
            </div>

            {!!onClose && (
              <IconButton
                size="xs"
                className="close-btn"
                aria-label="close"
                onClick={onClose}
              >
                <Icon icon="close" />
              </IconButton>
            )}
          </div>
        </div>
      </CSSTransition>
    );
  }
);
