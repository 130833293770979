import React, { ComponentPropsWithoutRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import './Link.scss';
import { TypographyVariant } from '../Typography';
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';

export type LinkVariant = 'inline' | 'standalone';

export interface LinkProps
  extends Omit<ComponentPropsWithoutRef<'a'>, 'onClick'>,
    CommonProps {
  /**
   * Specify the size of the inline link, from the following list of sizes:
   */
  size?: TypographyVariant;
  /**
   * Specify the variant of link, from the following list of variants:
   */
  variant: LinkVariant;
  /**
   * Optionally specify an onClick for your <a> tag to become an button element, and provide an optional function to be called when the button element is clicked
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optionally specify a link's destination
   */
  href?: string;
  /**
   * Specifies where to open the linked document
   */
  target?: string;
  /**
   * Optionally specify whether or not to apply CSS styling to visited links
   */
  enableVisitedStyles?: boolean;
}

const defaultElement = 'a';

export const Link: PolymorphicForwardRefExoticComponent<
  LinkProps,
  typeof defaultElement
> = React.forwardRef(function Link<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    href,
    size,
    children,
    variant,
    onClick,
    className,
    enableVisitedStyles = false,
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<LinkProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = onClick
    ? 'button'
    : as || defaultElement;

  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);

  const role = () => {
    if (Component === 'button') {
      return 'button';
    }
    if (Component === 'a' || typeof Component === 'object') {
      return 'link';
    }
    return undefined;
  };

  return (
    <Component
      {...rest}
      ref={ref}
      id={id}
      className={clsx([
        `${cssPrefix}-link`,
        className,
        size && `${cssPrefix}-link-size-${size}`,
        variant,
        enableVisitedStyles && 'visited-link',
      ])}
      role={role()}
      href={href}
      onClick={onClick}
    >
      {children}
    </Component>
  );
});
