import React, { forwardRef, useContext } from 'react';
import clsx from 'clsx';
import { isArray } from 'lodash';
import { useId } from '@reach/auto-id';
import { Sizes } from '../internal/types';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import './ToggleButton.scss';
import { ButtonProps } from '../Button';
import {
  IToggleButtonGroupContext,
  ToggleButtonGroupContext,
} from './ToggleButtonGroupContext';

export type ToggleButtonRef = HTMLButtonElement;

export interface ToggleButtonProps
  extends Omit<
      ButtonProps,
      | 'size'
      | 'variant'
      | 'href'
      | 'color'
      | 'loading'
      | 'fullWidth'
      | 'onClick'
    >,
    CommonProps {
  /**
   * Specify the size of the component, from the following list of sizes:
   *
   * @default 'md'
   */
  size?: Sizes;
  /**
   * The value of the button
   *
   * @default false
   */
  value: any;
  /**
   * Specify whether the component should be selected, or not
   *
   * @default false
   */
  selected?: boolean;
  /**
   * Specify a callback when the component is clicked.
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>, value: any) => void;
}

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  (
    {
      size,
      className,
      disabled,
      value,
      selected,
      onClick,
      children,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const toggleButtonGroupContext: IToggleButtonGroupContext | null =
      useContext(ToggleButtonGroupContext);

    const sizeValueToUse = size ?? toggleButtonGroupContext?.size ?? 'md';
    const disabledValueToUse = disabled ?? toggleButtonGroupContext?.disabled;

    const getSelectedValueFromContext = (contextSelectedValue: any) => {
      if (isArray(contextSelectedValue)) {
        return contextSelectedValue.includes(value);
      }
      return value === contextSelectedValue;
    };
    const selectedValueToUse =
      selected ??
      getSelectedValueFromContext(toggleButtonGroupContext?.selected);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      // fire context callback if present
      toggleButtonGroupContext?.onClick?.(event, value);
      // Fire callback if present
      onClick?.(event, value);
    };

    return (
      <button
        {...rest}
        ref={ref}
        id={id}
        className={clsx([
          `${cssPrefix}-togglebutton`,
          `button-size-${sizeValueToUse}`,
          className,
          selectedValueToUse && 'selected',
          disabledValueToUse && 'disabled',
          !toggleButtonGroupContext && 'show-border', // showing border for standalone buttons
          typeof children === 'string' && 'text-only',
        ])}
        type="button"
        disabled={disabledValueToUse}
        onClick={handleClick}
        value={value}
        tabIndex={disabledValueToUse ? -1 : undefined}
      >
        {children}
      </button>
    );
  }
);
