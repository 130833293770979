/**
 * Returns 1 of the 2 strings, depending on the count.
 * TODO - Remove this when we implement localization.
 */
export default function pluralize(
  count: number,
  singular: string,
  plural: string
): string {
  return count === 1 ? singular : plural;
}
