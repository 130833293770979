import React, {
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
  createContext,
  useRef,
} from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Placement } from '@popperjs/core';
import { CommonProps } from '../internal/interfaces';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';
import { ButtonProps } from '../Button';
import { BasePopperProps, BasePopper } from '../internal/components/BasePopper';
import { useCSSPrefix, useForwardedRef } from '../internal/hooks';
import { Key } from '../internal/enums/Key';
import './PopoverMenu.scss';

export interface PopoverMenuProps
  extends Omit<BasePopperProps, 'referenceElement'>,
    CommonProps {
  /**
   * Optionally specify a `maxHeight` value (px, rem, em, etc.) for the `PopoverMenu` window to enable scrolling when multiple options available.
   */
  maxHeight?: string;
  /**
   * Optionally initialize the `PopoverMenu` window to be open
   */
  defaultShow?: boolean;
  /**
   * Optionally specify the location in which the Popper will appear relative to the `referenceElement`
   */
  placement?: Placement;
}

interface IPopoverMenuItem extends ButtonProps {
  /**
   * Specify an id for each PopoverMenuItem. This is used for keyboard navigation.
   */
  id?: string;
}
export interface PopoverToggleButtonProps {
  /**
   * Specify the availability and type of interactive popup element that can be triggered by the element on which the attribute is set
   */
  ariaHasPopup?: boolean;
  /**
   * Specify text that identifies the element (or elements) whose contents or presence are controlled by the element on which this attribute is set.
   */
  ariaControls?: string;
}

export interface PopoverMenuContentProps extends CommonProps {
  /**
   * Specify the content of `MenuContent`. This should be comprised of `PopoverMenuItems`.
   */
  children: ReactNode;
}

const defaultElement = 'button';

export interface IPopoverMenuContext {
  navigatedItem: string;
  setNavigatedItem: React.Dispatch<React.SetStateAction<string>>;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setReferenceElement: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  referenceElement: HTMLButtonElement | null;
  placement?: Placement;
  maxHeight?: string;
  popperIsOpen: boolean;
  itemKeys: Element[];
  setListRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export const PopoverMenuContext = createContext<IPopoverMenuContext | null>(
  null
);

export const PopoverMenu = ({
  children,
  placement = 'bottom-start',
  maxHeight,
  defaultShow,
}: PopoverMenuProps) => {
  const [cssPrefix] = useCSSPrefix();
  const [listRef, setListRef] = useState<HTMLElement | null>(null);
  const [itemKeys, setItemKeys] = useState<Element[]>([]);
  const [navigatedItem, setNavigatedItem] = useState<string>('');
  const [show, setShow] = useState(false);
  const popperIsOpen = !!listRef;
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setShow(!!defaultShow);
  }, []);

  // stores array of PopoverMenu items from PopoverMenu list children as 'itemKeys' for keyboard navigation
  useEffect(() => {
    if (!listRef) {
      setNavigatedItem('');
      return;
    }
    // set focus when popper opens
    listRef.focus({
      preventScroll: true,
    });
    //  create list of <li /> tag id's
    const listItems = Array.from(listRef.children).filter((li) =>
      li.classList.contains(`${cssPrefix}-popover-menu-item`)
    );

    setItemKeys(listItems);
  }, [listRef]);

  useEffect(() => {
    const itemElement = document.getElementsByClassName('highlighted')[0];
    if (itemElement) {
      scrollIntoView(itemElement, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
        boundary: itemElement.parentElement,
      });
    }
  }, [navigatedItem]);

  const value = useMemo(
    () => ({
      navigatedItem,
      setNavigatedItem,
      show,
      setShow,
      setReferenceElement,
      referenceElement,
      placement,
      maxHeight,
      popperIsOpen,
      itemKeys,
      setListRef,
    }),
    [
      navigatedItem,
      setNavigatedItem,
      show,
      setShow,
      setReferenceElement,
      referenceElement,
      placement,
      maxHeight,
      popperIsOpen,
      itemKeys,
      setListRef,
    ]
  );

  return (
    <PopoverMenuContext.Provider value={value}>
      {children}
    </PopoverMenuContext.Provider>
  );
};

export const PopoverMenuToggleButton: PolymorphicForwardRefExoticComponent<
  PopoverToggleButtonProps,
  typeof defaultElement
> = React.forwardRef(function PopoverMenuToggleButton<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    ariaHasPopup,
    ariaControls,
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<PopoverToggleButtonProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = as || defaultElement;
  const id = useId(idProp);
  const [cssPrefix] = useCSSPrefix();
  const { show, setReferenceElement } = useContext(PopoverMenuContext) || {};

  return (
    <Component
      aria-expanded={show || undefined}
      aria-haspopup={ariaHasPopup}
      aria-controls={show ? ariaControls : undefined}
      ref={useForwardedRef(setReferenceElement, ref)}
      id={id}
      role="button"
      className={`${cssPrefix}-popover-toggle-button`}
      {...rest}
    />
  );
});

export const PopoverMenuContent = forwardRef<
  HTMLDivElement,
  PopoverMenuContentProps
>(({ children, id: idProp, ...rest }, ref) => {
  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);
  const {
    show,
    setShow,
    referenceElement,
    placement,
    maxHeight,
    popperIsOpen,
    itemKeys,
    setNavigatedItem,
    navigatedItem,
    setListRef,
  } = useContext(PopoverMenuContext) || {};

  const navigate = (option: string) => {
    setNavigatedItem?.(option);
    requestAnimationFrame(() => {
      document.getElementById(option)?.focus();
    });
  };

  const moveIndex = (curIndex: number, isNext: boolean): number => {
    let newIndex = curIndex + (isNext ? 1 : -1);

    if (newIndex >= (itemKeys?.length ?? Number.MAX_SAFE_INTEGER)) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = (itemKeys?.length ?? 1) - 1;
    }

    return newIndex;
  };

  const getOption = (isNext: boolean) => {
    if (itemKeys?.length === 0) return;

    const activeIndex = itemKeys?.findIndex(
      (opt: Element) => opt.id === navigatedItem
    );

    // moves the index by 1, either forward or backwards. loops around if we go out of bounds.

    // loop until we find a non-disabled item, or we loop around to our starting point
    for (
      let i = moveIndex(activeIndex!, isNext);
      i !== activeIndex;
      i = moveIndex(i, isNext)
    ) {
      if (!itemKeys?.[i].classList.contains('disabled')) {
        return itemKeys?.[i].id;
      }
    }

    // couldn't find a non-disabled option
  };

  const handleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (
      [Key.ArrowDown, Key.ArrowUp, Key.Escape, Key.Tab].includes(e.key as Key)
    ) {
      e.preventDefault();
    }

    if ((e.key === Key.Escape || e.key === Key.Tab) && popperIsOpen) {
      setShow?.(false);
      return referenceElement?.focus();
    }

    if (popperIsOpen && e.key === Key.Tab && e.shiftKey) {
      setShow?.(false);
      return referenceElement?.focus();
    }
    if (e.key === Key.ArrowUp) {
      const option = getOption(false);
      return !!option && navigate(option);
    }

    if (e.key === Key.ArrowDown) {
      const option = getOption(true);
      return !!option && navigate(option);
    }
  };

  const forwardedRef = useForwardedRef(setListRef, ref);

  return referenceElement ? (
    <BasePopper
      show={show}
      setShow={setShow}
      placement={placement}
      referenceElement={referenceElement}
      showOnElement={referenceElement}
      showOnElementEvents={['click']}
      {...rest}
    >
      <div
        style={{ maxHeight }}
        tabIndex={0} // to make focusable / enable onKeyDown
        onKeyDown={handleButtonKeyDown}
        className={`${cssPrefix}-popover-menu-list`}
        role="menu"
        ref={forwardedRef}
        id={id}
      >
        {children}
      </div>
    </BasePopper>
  ) : null;
});

export const PopoverMenuItem: PolymorphicForwardRefExoticComponent<
  IPopoverMenuItem,
  typeof defaultElement
> = React.forwardRef(function PopoverMenuItem<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    id: idProp,
    disabled,
    children,
    onClick,
    className,
    ...rest
  }: PolymorphicPropsWithoutRef<IPopoverMenuItem, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = as || defaultElement;
  const [cssPrefix] = useCSSPrefix();
  const { navigatedItem, setShow } = useContext(PopoverMenuContext) || {};
  const linkRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
  const id = useId(idProp);
  const isItemHighlighted = (key: string) => key === navigatedItem;

  return (
    <Component
      {...rest}
      ref={useForwardedRef(linkRef, ref)}
      id={id}
      className={clsx([
        `${cssPrefix}-popover-menu-item`,
        id && isItemHighlighted(id) && 'highlighted',
        disabled && 'disabled',
        className,
      ])}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        !!onClick && onClick(e);
        setShow?.(false);
      }}
      tabIndex={0}
      disabled={disabled}
      role="menuitem"
    >
      {children}
    </Component>
  );
});
