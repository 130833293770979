import React, {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  forwardRef,
  ReactNode,
} from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { Icon, IconType } from '../Icon';
import './MessageBlock.scss';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { useCSSPrefix } from '../internal/hooks';
import { SeverityColors } from '../internal/types';

export type IconMappingType = {
  [key in SeverityColors]: IconType;
};

// Default Icon Mapping
export const MessageBlockIconSet: IconMappingType = {
  success: 'check',
  warning: 'warning-filled',
  info: 'info-filled',
  error: 'error-filled',
};

export interface MessageBlockProps
  extends ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify the content of your Message Block
   */
  children: ReactNode;
  /**
   * Specify the 'severity', the status based on severity
   */
  severity?: SeverityColors;
  /**
   * Override the default Icon with another MessageBlock icon
   */
  icon?: IconType | boolean;
  /**
   * Specify the title text at the top of the Message Block
   */
  title?: string;
  /**
   * Specify a function that will run when the 'close' button is clicked
   */
  onClose?: () => void;
}

export const MessageBlock = React.forwardRef<HTMLDivElement, MessageBlockProps>(
  (
    {
      id: idProp,
      children,
      severity = 'info',
      title,
      icon,
      onClose,
      className,
      ...args
    },
    ref
  ) => {
    const messageBlockId = useId(idProp);
    const [cssPrefix] = useCSSPrefix();

    return (
      <div
        {...args}
        ref={ref}
        id={messageBlockId}
        className={clsx([
          `${cssPrefix}-message-block`,
          `${cssPrefix}-${severity}`,
          className,
        ])}
        role="alert"
      >
        <div
          id={`${messageBlockId}-message-block-icon`}
          className={`${cssPrefix}-icon-col`}
        >
          {icon === false ? null : (
            <Icon
              icon={
                typeof icon === 'string' ? icon : MessageBlockIconSet[severity]
              }
              size="xs"
            />
          )}
        </div>

        <div className={`${cssPrefix}-content-col`}>
          {title && (
            <div className={`${cssPrefix}-message-block-title`}>
              <Typography fontWeight="bold">{title}</Typography>
            </div>
          )}

          {children && (
            <div
              className={clsx([!title && `${cssPrefix}-content-padding-top`])}
            >
              {children}
            </div>
          )}
        </div>

        <div
          className={clsx([
            `${cssPrefix}-close-btn-col`,
            !onClose && `${cssPrefix}-no-close-btn-padding`,
          ])}
        >
          {!!onClose && (
            <div className={`${cssPrefix}-icon-button-col`}>
              <IconButton
                color="default"
                size="xs"
                aria-label="close"
                onClick={onClose}
              >
                <Icon icon="close" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export const MessageBlockContent = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(({ children }: ComponentPropsWithRef<'div'>, ref) => {
  const [cssPrefix] = useCSSPrefix();
  return children ? (
    <div className={`${cssPrefix}-message-block-content`} ref={ref}>
      <Typography variant="body1">{children}</Typography>
    </div>
  ) : null;
});

export const MessageBlockActions = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(({ children }: ComponentPropsWithRef<'div'>, ref) => {
  const [cssPrefix] = useCSSPrefix();
  return (
    <div className={`${cssPrefix}-message-block-actions`} ref={ref}>
      {children}
    </div>
  );
});
