import React from 'react';

export interface ITabsContext {
  keys: HTMLButtonElement[];
  onChange: (id: string) => void;
  setActiveRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  activeRef: HTMLElement | null;
  navigationValue: string;
  setNavigationValue: React.Dispatch<React.SetStateAction<string>>;
  fullWidth: boolean;
  color: 'primary' | 'secondary';
}

export const TabsContext = React.createContext<ITabsContext | null>(null);
