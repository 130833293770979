import React from 'react';
import { Colors } from '../internal/types';

export interface StepperContextType {
  color: Colors;
  activeStep: number;
  orientation: 'horizontal' | 'vertical';
}

/**
 * Provides information about the current step in Stepper.
 */
const StepperContext = React.createContext<StepperContextType | null>(null);

/**
 * Returns the current StepperContext or an empty object if no StepperContext
 * has been defined in the component tree.
 */
export function useStepperContext(): StepperContextType | null {
  return React.useContext(StepperContext);
}

export default StepperContext;
