import { ReactElement, ReactNode } from 'react';

/**
 * Converts a ReactNode into a string, without any html tags. This is needed in order
 * to announce some elements to screenreaders.
 */
export default function reactNodeToString(node: ReactNode): string {
  if (!node) return '';

  if (typeof node === 'string') return node;
  if (typeof node === 'number') return `${node}`;

  if ((node as ReactElement)?.props?.children) {
    return reactNodeToString((node as ReactElement).props.children);
  }

  if (Array.isArray(node)) {
    return node.map((child) => reactNodeToString(child)).join('');
  }

  return '';
}
