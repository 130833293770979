import React, { forwardRef, useRef } from 'react';
import { useId } from '@reach/auto-id';
import clsx from 'clsx';
import './Switch.scss';
import { CSSTransition } from 'react-transition-group';
import { InputFieldProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import { Icon, IconType } from '../Icon';
import { useControlledState } from '../internal/hooks/useControlledState';
import { InputLabel } from '../internal/components/InputLabel';
import { HelperText } from '../internal/components/HelperText';

export interface SwitchProps
  extends React.ComponentPropsWithRef<'input'>,
    InputFieldProps {
  /**
   * If controlled, specify the "on" state
   */
  checked?: boolean;
  /**
   * If controlled, specify a function to be called when the Switch value changes
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * If uncontrolled, specify the default "on" state
   */
  defaultChecked?: boolean;
  /**
   * If provided, displays an icon in the thumb when switch is toggled "on"
   */
  icon?: IconType;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      className,
      defaultChecked = false,
      checked: checkedProp,
      label,
      onChange,
      errorText,
      successText,
      helperText,
      disabled,
      icon,
      id: idProp,
      'data-testid': dataTestId,
      style,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    // removes CSSTransition findDOMNode error in StrictMode
    const nodeRef = useRef(null);
    const id = useId(idProp);

    const [checked, setChecked] = useControlledState(
      defaultChecked,
      checkedProp,
      'Switch'
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // un-controlled
      if (checkedProp === undefined) {
        setChecked(e.target.checked);
      }
      // controlled
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <div
        id={id}
        data-testid={dataTestId}
        style={style}
        className={clsx([`${cssPrefix}-switch-wrapper`, className])}
      >
        <InputLabel
          id={`${id}-label`}
          data-testid={`${dataTestId}-label`}
          className={`${cssPrefix}-switch-label`}
          disabled={disabled}
        >
          <div className={clsx([`${cssPrefix}-switch`])}>
            <div
              className={clsx([
                `${cssPrefix}-switch-base`,
                disabled && 'disabled',
                checked && 'checked',
              ])}
            >
              <input
                {...props}
                id={`${id}-input`}
                data-testid={`${dataTestId}-input`}
                type="checkbox"
                className={clsx([
                  `${cssPrefix}-switch-input`,
                  disabled && 'disabled',
                ])}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                ref={ref}
              />
              <CSSTransition
                in={checked}
                timeout={150}
                appear
                classNames={`${cssPrefix}-switch-animation`}
                nodeRef={nodeRef}
              >
                <div
                  className={clsx([`${cssPrefix}-switch-thumb`])}
                  ref={nodeRef}
                >
                  {checked && icon && (
                    <Icon
                      icon={icon}
                      size="xs"
                      className={clsx([`${cssPrefix}-switch-icon`])}
                    />
                  )}
                </div>
              </CSSTransition>
              <div className={clsx([`${cssPrefix}-switch-track`])} />
            </div>
          </div>
          {label}
        </InputLabel>
        <HelperText
          errorText={errorText}
          successText={successText}
          helperText={helperText}
        />
      </div>
    );
  }
);
