import debounce from 'lodash/debounce';
import { AsyncStatus } from '../enums';

export type SearcherResults<TResult, TCursor> = Promise<{
  /**
   * Tells the searcher whether there are more results to load.
   */
  canLoadMore: boolean;
  /**
   * Search results from your API.
   */
  results: TResult[];
  /**
   * Optional search session data returned from the previous call to onSearch.
   * This is useful for search backends that use tokens for pagination instead of limit/offset.
   *  - Will be `undefined` on the first search in a search session.
   *  - If you return a value for `cursor`, it will be passed the next time `onSearch` is called
   * within this search session, like if a user scrolls down and we need to load more results.
   *  - The cursor will be reset if the user changes their query.
   */
  cursor?: TCursor;
}>;
export type SearcherOnSearch<TResult, TCursor> = (
  /**
   * The text that the user has typed into the search input.
   */
  query: string,
  /**
   * The index of the next search result to load. This increases from 0 as the user scrolls down.
   */
  offset: number,
  /**
   * Optional search session data returned from the previous call to onSearch. See notes on SearcherResults.
   */
  cursor?: TCursor
) => SearcherResults<TResult, TCursor>;

export interface SearcherOptions {
  /**
   * Number of milliseconds to debounce. Defaults to 200.
   */
  debounceDelay?: number;
}

/**
 * Searcher abstracts out the logic for getting search results from an API. It supports debouncing and prevents
 * showing stale search results, like if the user searches again while a search is in progress.
 */
export class Searcher<TResult, TCursor> {
  public canLoadMore: boolean = false;
  public error: any;
  public results: TResult[] = [];
  public status: AsyncStatus = AsyncStatus.Initial;

  private curSearchId = 0;
  private cursor: TCursor | undefined;
  private query: string = '';

  constructor(
    public onSearch: SearcherOnSearch<TResult, TCursor>,
    private options: SearcherOptions = {
      debounceDelay: 200,
    }
  ) {
    // Initialize default values. We're using reset to do this so that we only have 1 code path
    // for setting default values.
    this.reset();
  }

  public reset() {
    this.canLoadMore = false;
    this.error = undefined;
    this.query = '';
    this.results = [];
    this.cursor = undefined;
    this.status = AsyncStatus.Initial;
    // Bump the most recent search id to invalidate any earlier searches.
    this.curSearchId++;
  }

  public search = (query: string): Promise<TResult[]> => {
    this.reset();
    this.query = query;
    this.status = AsyncStatus.Loading;
    return this._search(query, false);
  };

  public searchDebounced = (query: string): Promise<TResult[]> => {
    // Even though we're debouncing the actual ajax call, we want to update our state immediately.
    this.reset();
    this.query = query;
    this.status = AsyncStatus.Loading;

    // Debounce doesn't support promises, so we need to create a promise,
    // then pass it to the debounced method so it can call resolve when it runs.
    return new Promise<TResult[]>((resolve, reject) => {
      this._searchDebounced(query, resolve, reject);
    });
  };

  // Loads more results if possible. Returns undefined if it cannot load more.
  public tryLoadMore = (): Promise<TResult[]> | undefined => {
    if (!this.canLoadMore || this.status !== AsyncStatus.Success)
      return undefined;

    this.canLoadMore = false;
    this.error = undefined;
    this.status = AsyncStatus.Loading;
    this.curSearchId++;

    return this._search(this.query, true);
  };

  // This is a helper method to debounce the search promise.
  private _searchDebounced = debounce((query: string, resolve, reject) => {
    this._search(query, false)
      .then((results) => {
        resolve(results);
      })
      .catch((err) => {
        reject(err);
      });
  }, this.options.debounceDelay);

  // Internal search method that can either search, or load more results from the last search.
  private _search(query: string, isLoadMore: boolean): Promise<TResult[]> {
    const mySearchId = this.curSearchId;

    // Wrap the search promise so that we can choose not to resolve it if
    // the user searches again before we get a response.
    return new Promise<TResult[]>((resolve, reject) => {
      // Call the search method we were passed in order to get some results.
      this.onSearch(query, this.results.length, this.cursor).then(
        ({ canLoadMore, results, cursor }) => {
          // Don't do anything if the user has searched again.
          if (mySearchId !== this.curSearchId) return;

          this.canLoadMore = canLoadMore;
          this.cursor = cursor;
          this.status = AsyncStatus.Success;

          if (isLoadMore) {
            this.results = this.results.concat(results);
          } else {
            this.results = results;
          }

          resolve(this.results);
        },
        (err) => {
          // Don't do anything if the user has searched again.
          if (mySearchId !== this.curSearchId) return;

          this.reset();
          this.error = err;
          this.status = AsyncStatus.Error;
          reject(err);
        }
      );
    });
  }
}
