import debounce from 'lodash/debounce';

/**
 * Listens for scroll events on an element. If the user scrolls near the bottom,
 * it will call `onNearBottom()`.
 */
export class InfiniteScroll {
  private isDestroyed = false;
  private scroller: HTMLElement | null = null;
  private windowDefined: boolean =
    typeof window !== 'undefined' && typeof window.document !== 'undefined';

  constructor(
    public onNearBottom: () => void,
    public maxDistanceFromBottom: number
  ) {
    this.windowDefined &&
      window.addEventListener('resize', this.checkDebounced);
  }

  /**
   * Checks scroll position to see if we're within the max distance from the bottom.
   */
  public check = () => {
    const el = this.scroller;
    if (el) {
      // Don't try to load more if we don't have any height. This happens in Jest tests,
      // and might could also happen if InfiniteScroll is used on a collapsed container.
      if (el.scrollHeight === 0 || el.clientHeight === 0) {
        return;
      }

      // See if we're near the bottom.
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
      const distFromBottom = el.scrollHeight - el.scrollTop - el.clientHeight;

      if (distFromBottom < this.maxDistanceFromBottom) {
        this.onNearBottom();
      }
    }
  };

  /**
   * We're debouncing for better performance during resize.
   */
  public checkDebounced = debounce(this.check, 250, { maxWait: 250 });

  /**
   * This callback should be set as a ref on the scrollable element like:
   * `<div ref={scrollerRef}>`
   */
  public scrollerRef = (node: HTMLElement | null) => {
    this.scroller = node;

    if (node) {
      // Do an immediate check in case we don't have enough loaded items to trigger a scrollbar,
      // in which case we wouldn't ever receive any scroll events.
      this.check();

      node.addEventListener('scroll', this.checkDebounced, { passive: true });

      return () => {
        node.removeEventListener('scroll', this.checkDebounced);
      };
    }
  };

  // Cleanup window event listener.
  public destroy = () => {
    if (this.isDestroyed) {
      return;
    }

    this.isDestroyed = true;
    this.windowDefined &&
      window.removeEventListener('resize', this.checkDebounced);
  };
}
