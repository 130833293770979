import React, { ComponentPropsWithoutRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import './Divider.scss';
import { useCSSPrefix } from '../internal/hooks';
import { CommonProps } from '../internal/interfaces';

export type DividerVariant =
  | 'middle'
  | 'fullLength'
  | 'insetStart'
  | 'insetEnd';
export type DividerOrientation = 'horizontal' | 'vertical';

export interface DividerProps
  extends ComponentPropsWithoutRef<'hr'>,
    CommonProps {
  /**
   * Specify the orientation of the divider component.
   */
  orientation?: DividerOrientation;
  /**
   * Specify the variant of the divider component.
   */
  variant?: DividerVariant;
  /**
   * Specify margin to be applied at the ends of the divider.
   */
  inset?: number;
}

export const Divider = React.forwardRef<HTMLHRElement, DividerProps>(
  (
    {
      orientation = 'horizontal',
      variant = 'middle',
      inset,
      style,
      className,
      id: idProp,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const insetStyle = {
      ...((variant === 'middle' || variant === 'insetStart') && {
        [orientation === 'vertical' ? 'marginTop' : 'marginLeft']: inset,
      }),
      ...((variant === 'middle' || variant === 'insetEnd') && {
        [orientation === 'vertical' ? 'marginBottom' : 'marginRight']: inset,
      }),
    };

    return (
      <hr
        {...props}
        ref={ref}
        id={id}
        style={{ ...style, ...insetStyle }}
        className={clsx([
          `${cssPrefix}-divider`,
          `${cssPrefix}-${orientation}`,
          `${cssPrefix}-${variant}`,
          className,
        ])}
      />
    );
  }
);
