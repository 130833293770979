import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import './Avatar.scss';
import { Sizes, Colors } from '../internal/types';
import { CommonProps } from '../internal/interfaces';
import { TopNavigationContext } from '../TopNavigation';
import { SideNavigationContext } from '../SideNavigation';
import { useCSSPrefix } from '../internal/hooks';
import { Icon } from '../Icon';
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';

export type AvatarVariant = 'filled' | 'outlined';

export type AvatarRef = HTMLButtonElement | HTMLAnchorElement | HTMLDivElement;

export interface AvatarProps
  extends React.HTMLAttributes<AvatarRef>,
    CommonProps {
  /**
   * By default the size will be md. Optionally, specify a size from the following list:
   */
  size?: Sizes;
  /**
   * Optional file path for image used as background for avatar. Passes the value to the src attribute of the img tag.
   */
  imageSrc?: string;
  /**
   * Optional alternate text for screen readers when imageSrc is provided
   */
  alt?: string;
  /**
   * Optional name used displaying initials when image is not provided
   */
  name?: string;
  /**
   * Specify the color of the avatar, from the following list of colors:
   */
  color?: Colors;
  /**
   * Specify the variant of the avatar, from the following list of variants:
   */
  variant?: AvatarVariant;
  /**
   * Optionally, specify a callback when the component is clicked. If provided, the component will render as a button.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optionally specify an href for your Avatar to become an `<a>` element
   */
  href?: string;
}

export interface AvatarGroupProps
  extends ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify at least one child avatar for the Avatar Group
   */
  children: ReactNode;
  /**
   * Optionally specify max number of avatars displayed
   */
  max?: number;
}

// get first initial of first word and first initial of last word provided
const getInitials = (name: string) => {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  } else {
    initials += name[1] ? name[1] : '';
  }
  return initials;
};

function getColorToUse(incomingColor?: Colors): Colors | undefined {
  if (incomingColor === 'secondary') return 'secondary';
  return 'primary';
}

function getVariantToUse(incomingColor?: Colors): AvatarVariant | undefined {
  if (incomingColor === 'secondary') return 'outlined';
  return 'filled';
}

const defaultElement = 'div';

export const Avatar: PolymorphicForwardRefExoticComponent<
  AvatarProps,
  typeof defaultElement
> = React.forwardRef(function Avatar<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    className,
    name,
    imageSrc,
    alt,
    size = 'md',
    color,
    variant,
    onClick,
    href,
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<AvatarProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = onClick
    ? 'button'
    : href
    ? 'a'
    : as || defaultElement;

  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);
  const [validImage, setValidImage] = useState(true);
  const { color: topNavColor } = useContext(TopNavigationContext) || {};
  const { color: sideNavColor } = useContext(SideNavigationContext) || {};

  const colorToUse =
    color ?? getColorToUse(topNavColor || sideNavColor) ?? 'primary';
  const variantToUse =
    variant ?? getVariantToUse(topNavColor || sideNavColor) ?? 'filled';

  const role =
    // if 'button'
    Component === 'button'
      ? 'button'
      : // if 'a' tag or React Router Link
      Component === 'a' || typeof Component === 'object'
      ? 'link'
      : null;

  return (
    <Component
      role={role}
      href={(Component === 'a' && href) || undefined}
      ref={ref}
      id={id}
      onClick={onClick}
      className={clsx([
        `${cssPrefix}-avatar`,
        `avatar-${size}`,
        `avatar-${colorToUse}`,
        `avatar-${variantToUse}`,
        role === 'button' && `avatar-button`,
        className,
      ])}
      {...rest}
    >
      {imageSrc && validImage ? (
        <img
          src={imageSrc}
          className="avatar-image"
          alt={alt}
          onError={() => setValidImage(false)}
        />
      ) : name ? (
        getInitials(name)
      ) : (
        <Icon icon="user" />
      )}
    </Component>
  );
});

export const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>(
  ({ children, className, max, id: idProp, ...rest }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    const avatarRef = React.useRef<[HTMLDivElement] | []>([]);
    const [avatarClass, setAvatarClass] = useState('cfa-avatar');
    const childrenLength = React.Children.count(children);
    const hasChildrenLengthReachedMax = max
      ? childrenLength === max
        ? childrenLength
        : max - 1
      : false;
    const maxAvatarShown = max
      ? hasChildrenLengthReachedMax
      : Number.MAX_SAFE_INTEGER;

    useEffect(() => {
      // get same styles for remainder button that avatar child has
      setAvatarClass(avatarRef!.current[0]!.className);
    }, [children]);

    const visibleAvatars: React.ReactElement[] = [];
    React.Children.forEach(children, (child: ReactNode, i: number) => {
      if (i < maxAvatarShown) {
        visibleAvatars.push(child as React.ReactElement);
      }
    });

    return (
      <div
        {...rest}
        ref={ref}
        id={id}
        className={clsx([`${cssPrefix}-avatar-group`, className])}
      >
        {visibleAvatars.map((child: React.ReactElement, index) =>
          React.cloneElement(child, {
            key: index,
            ref: (childRef: HTMLDivElement) => {
              avatarRef.current[index] = childRef;
            },
          })
        )}

        {max && childrenLength > max && (
          <div data-testid="avatar-group-remainder" className={avatarClass}>
            {maxAvatarShown ? childrenLength - maxAvatarShown : childrenLength}+
          </div>
        )}
      </div>
    );
  }
);
