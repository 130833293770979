import React, { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix, useControlledState } from '../internal/hooks';
import { ToggleButtonProps } from '.';
import { ToggleButtonGroupContext } from './ToggleButtonGroupContext';
import './ToggleButtonGroup.scss';

export type ToggleButtonGroupOrientation = 'horizontal' | 'vertical';

export interface ToggleButtonGroupProps
  extends Pick<ToggleButtonProps, 'children' | 'size'>,
    CommonProps {
  /**
   * Specify if the Toggle Button Group should take up the full width of its container
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Specify the orientation of the Toggle Button Group
   */
  orientation?: ToggleButtonGroupOrientation;
  /**
   * Specify whether the Toggle Button Group should be disabled, or not
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Specify whether multiple children can be selected at a given time, or not
   *
   * @default false
   */
  enableMultipleSelection?: boolean;
  /**
   * Specify whether the borders between children should be hidden, or not
   *
   * @default false
   */
  hideSeparators?: boolean;
  /**
   * If controlled, specifies the selected value within the group. Should be an array if enableMultipleSelection is true.
   */
  value?: any;
  /**
   * If uncontrolled, specifies a default selected value within the Toggle Button Group.
   */
  defaultValue?: any;
  /**
   * Optionally, specify a callback when the component state changes.
   */
  onChange?: (event: React.MouseEvent<HTMLButtonElement>, value: any) => void;
}

export const ToggleButtonGroup = forwardRef<
  HTMLDivElement,
  ToggleButtonGroupProps
>(
  (
    {
      orientation = 'horizontal',
      disabled = false,
      size = 'md',
      fullWidth = false,
      enableMultipleSelection = false,
      hideSeparators = false,
      onChange = undefined,
      defaultValue,
      value: valueProp,
      className,
      children,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const [selected, setSelected] = useControlledState<any>(
      defaultValue,
      valueProp,
      'ToggleButtonGroup'
    );

    const onClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      toggleButtonValue: any
    ) => {
      let newSelectedValue =
        // if button is already selected, return null
        toggleButtonValue === selected ? null : toggleButtonValue;
      if (enableMultipleSelection) {
        const selectedArray = selected ? [...selected] : [];
        // if item is already selected, remove from array
        if (selectedArray.includes(toggleButtonValue)) {
          newSelectedValue = selectedArray.filter(
            (selectedValue) => selectedValue !== toggleButtonValue
          );
          // else add it to the array, dependent on the enableMultipleSelection prop
        } else {
          newSelectedValue = [...selectedArray, toggleButtonValue];
        }
      }

      setSelected(newSelectedValue);

      // fire optional onChange event with the new state value
      onChange?.(event, newSelectedValue);
    };

    const value = useMemo(
      () => ({
        size,
        disabled,
        selected: valueProp ?? selected,
        onClick,
      }),
      [size, disabled, selected, valueProp]
    );

    return (
      <ToggleButtonGroupContext.Provider value={value}>
        <div
          {...rest}
          ref={ref}
          id={id}
          className={clsx([
            className,
            `${cssPrefix}-toggle-buttongroup`,
            orientation,
            fullWidth && 'fullwidth',
            !hideSeparators && 'separators',
          ])}
          role="group"
        >
          {children}
        </div>
      </ToggleButtonGroupContext.Provider>
    );
  }
);
