import * as React from 'react';
import clsx from 'clsx';
import { CommonProps } from '../internal/interfaces';

export interface ContainerProps
  extends React.HTMLAttributes<HTMLElement>,
    CommonProps {
  /**
   * Allow the Container to fill all of its available horizontal space.
   *
   * @type {(true|"sm"|"md"|"lg"|"xl"|"xxl")}
   */
  fluid?: boolean | string | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  /**
   * Optionally specify the underlying HTML element
   */
  as?: React.ElementType;
}

export const Container = React.forwardRef<HTMLElement, ContainerProps>(
  (
    {
      fluid,
      as: Component = 'div',
      className,
      'data-testid': dataTestId = 'container',
      ...props
    },
    ref
  ) => {
    const prefix = 'container';
    const suffix = typeof fluid === 'string' ? `-${fluid}` : '-fluid';
    return (
      <Component
        data-testid={dataTestId}
        ref={ref}
        {...props}
        className={clsx([className, fluid ? `${prefix}${suffix}` : prefix])}
      />
    );
  }
);
