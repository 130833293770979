import React, { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks';
import './Card.scss';
import { Key } from '../internal/enums';
import { SurfaceProps, Surface } from '../Surface';

export interface CardProps
  extends ComponentPropsWithoutRef<'div'>,
    Pick<SurfaceProps, 'variant' | 'elevation'>,
    CommonProps {
  /**
   * Specify the content of the Card
   */
  children?: ReactNode;
  /**
   * Provide an optional function to be called when the card is clicked
   */
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
}

export interface CardMediaProps
  extends ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * File path for image. Passes the value to the src attribute of the img tag.
   */
  imageSrc: string;
  /**
   * Optional alternate text for screen readers when imageSrc is provided
   */
  alt?: string;
  /**
   * Optionally, specify a height for the image either as a number (pixel value) or string with valid units (rem, percentage, etc.)
   */
  height?: string | number;
}

export interface CardContentActionProps
  extends ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify children of the component
   */
  children: ReactNode;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      children,
      variant = 'default',
      onClick,
      elevation = 1,
      id: idProp,
      ...args
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (onClick && (e.key === Key.Space || e.key === Key.Enter)) {
        onClick(e);
      }
    };

    return (
      <Surface
        {...args}
        ref={ref}
        id={id}
        className={clsx([
          `${cssPrefix}-card`,
          `card-${variant}`,
          onClick && `card-button`,
          className,
        ])}
        role={onClick && 'button'}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={onClick && 0}
        onClick={onClick}
        variant={variant}
        elevation={elevation}
        onKeyDown={handleKeyDown}
      >
        {children}
      </Surface>
    );
  }
);

export const CardMedia = forwardRef<HTMLDivElement, CardMediaProps>(
  ({ imageSrc, alt, children, height, id: idProp, ...props }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    return (
      <div
        ref={ref}
        id={id}
        className={clsx([`${cssPrefix}-card-media`])}
        {...props}
      >
        <img
          src={imageSrc}
          alt={alt}
          height={height}
          className="card-media-img"
        />
      </div>
    );
  }
);

export const CardContent = forwardRef<HTMLDivElement, CardContentActionProps>(
  ({ className, children, id: idProp, ...props }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    return (
      <div
        {...props}
        ref={ref}
        id={id}
        className={clsx([`${cssPrefix}-card-content`, className])}
      >
        {children}
      </div>
    );
  }
);

export const CardActions = forwardRef<HTMLDivElement, CardContentActionProps>(
  ({ className, children, id: idProp, ...props }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);
    return (
      <div
        {...props}
        ref={ref}
        id={id}
        className={clsx([`${cssPrefix}-card-actions`, className])}
      >
        {children}
      </div>
    );
  }
);
