import React, { useContext } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { MoreColors, Colors } from '../internal/types';
import { TopNavigationContext } from '../TopNavigation';
import { SideNavigationContext } from '../SideNavigation';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import './IconButton.scss';
import { ButtonProps } from '../Button';
import { IconProps } from '../Icon';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';
import { IconButtonGroupContext } from '../IconButtonGroup';

export type IconButtonRef = HTMLButtonElement | HTMLAnchorElement;

export interface IconButtonProps
  extends Omit<
      ButtonProps,
      'color' | 'size' | 'variant' | 'fullWidth' | 'loading'
    >,
    Pick<IconProps, 'size'>,
    CommonProps {
  /**
   * Specify the color of the component, from the following list of colors:
   */
  color?: MoreColors;
}

function getColorToUse(incomingColor?: Colors): MoreColors | undefined {
  if (incomingColor === 'secondary') return 'tertiary';
  return 'default';
}

const defaultElement = 'button';

export const IconButton: PolymorphicForwardRefExoticComponent<
  IconButtonProps,
  typeof defaultElement
> = React.forwardRef(function IconButton<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    color,
    size,
    className,
    disabled,
    onClick = undefined,
    href = undefined,
    children,
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<IconButtonProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = href ? 'a' : as || defaultElement;

  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);
  const { color: topNavColor } = useContext(TopNavigationContext) || {};
  const { color: sideNavColor } = useContext(SideNavigationContext) || {};

  const {
    color: iconButtonGroupColor,
    size: iconButtonGroupSize,
    disabled: iconButtonGroupDisabled,
  } = useContext(IconButtonGroupContext) || {};

  const colorToUse =
    color ?? iconButtonGroupColor ?? getColorToUse(topNavColor || sideNavColor);

  const sizeToUse = size ?? iconButtonGroupSize;
  const disabledValueToUse = disabled ?? iconButtonGroupDisabled;

  const role =
    // if 'button'
    Component === 'button'
      ? 'button'
      : // if 'a' tag or React Router Link
      Component === 'a' || typeof Component === 'object'
      ? 'link'
      : null;

  // If the component is used as a 'button' then we explicitly set type="button"
  // Otherwise, it defaults to type="submit" which can cause unintended behavior when use in a form.

  const buttonType = Component === 'button' ? 'button' : undefined;

  return (
    <Component
      {...rest}
      ref={ref}
      id={id}
      className={clsx([
        `${cssPrefix}-iconButton`,
        role === 'link' && `iconButton-link`,
        colorToUse,
        sizeToUse,
        className,
      ])}
      disabled={disabledValueToUse}
      type={buttonType}
      role={role}
      href={(Component === 'a' && href) || undefined}
      onClick={onClick}
    >
      {children}
    </Component>
  );
});
