import React, { MutableRefObject, useContext } from 'react';
import clsx from 'clsx';
import { useCSSPrefix } from '../internal/hooks';
import { TabsContext } from './TabsContext';

const focusRingBorderWidth = 4;

export interface SelectedSliderProps {
  sliderRootEl: MutableRefObject<HTMLDivElement | null>;
}

export const SelectedSlider = ({ sliderRootEl }: SelectedSliderProps) => {
  const { activeRef, color } = useContext(TabsContext) || {};
  const [cssPrefix] = useCSSPrefix();

  let style = {};

  if (activeRef) {
    style = {
      transform: `translate(${
        (activeRef?.offsetLeft ?? 0) -
        (sliderRootEl.current?.offsetLeft ?? 0) -
        focusRingBorderWidth
      }px)`,
      width: `${
        (activeRef?.getBoundingClientRect().width ?? 0) +
        2 * focusRingBorderWidth
      }px`,
    };
  }

  return (
    <hr
      className={clsx([
        `${cssPrefix}-horizontal-slider`,
        color === 'secondary' && `${cssPrefix}-secondary`,
      ])}
      style={style}
    />
  );
};
