import React, { createContext, forwardRef, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { useCSSPrefix } from '../internal/hooks';
import { CommonProps } from '../internal/interfaces';
import './List.scss';

export type ListVariant = 'default' | 'ul' | 'ol';

interface IListContext {
  /** Optionally, specify the variant to use from the following list: */
  variant?: ListVariant;
  /** If `variant="default"` then optionally specify a divider style to use. */
  divider?: 'full' | 'inset';
}

const ListThemeContext = createContext<IListContext | null>(null);

export type ListRefType = HTMLUListElement | HTMLOListElement;

export interface ListProps
  extends React.HTMLAttributes<ListRefType>,
    CommonProps,
    IListContext {
  /** The content of the component. */
  children: React.ReactNode;
}

export const List = forwardRef<ListRefType, ListProps>(
  (
    {
      id,
      variant = 'default',
      divider,
      children,
      className,
      style,
      'data-testid': dataTestId,
      ...props
    },
    ref
  ) => {
    const listId = useId(id);
    const [cssPrefix] = useCSSPrefix();
    const listTheme = useMemo(() => ({ variant, divider }), [variant, divider]);
    const baseElement = variant === 'default' ? 'ul' : variant;
    const Component = baseElement as React.ElementType;

    return (
      <Component
        {...props}
        ref={ref}
        id={listId}
        style={style}
        className={clsx(
          `${cssPrefix}-list`,
          variant === 'default' && `${cssPrefix}-default-list`,
          className
        )}
        data-testid={dataTestId}
      >
        <ListThemeContext.Provider value={listTheme}>
          {children}
        </ListThemeContext.Provider>
      </Component>
    );
  }
);

interface ListItemContainerProps {
  /** Optionally, specify a function to be called when the list item is clicked. If not present, the list item will not be clickable. */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** The content of the component. */
  children: React.ReactNode;
}

const ListItemContainer = ({ onClick, children }: ListItemContainerProps) => {
  const [cssPrefix] = useCSSPrefix();

  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={clsx([
          `${cssPrefix}-list-item-container`,
          `${cssPrefix}-list-item-button`,
        ])}
      >
        {children}
      </button>
    );
  }

  return <div className={`${cssPrefix}-list-item-container`}>{children}</div>;
};

interface ListItemRowProps {
  /** The content of the component. */
  children: React.ReactNode;
}

const ListItemRow = ({ children }: ListItemRowProps) => {
  const [cssPrefix] = useCSSPrefix();
  return <div className={`${cssPrefix}-list-item-row`}>{children}</div>;
};

interface ListItemColProps extends CommonProps {
  /** The content of the component. */
  children: React.ReactNode;
}

const ListItemCol = ({ className, children }: ListItemColProps) => {
  const [cssPrefix] = useCSSPrefix();

  if (children) {
    return (
      <div className={clsx([`${cssPrefix}-list-item-col`, className])}>
        {children}
      </div>
    );
  }

  return null;
};
export interface ListItemProps
  extends React.HTMLAttributes<HTMLLIElement>,
    CommonProps {
  /** Optionally, specify the content to render at the start of the list item. */
  startItem?: React.ReactNode;
  /** Optionally, specify the content to render at the end of the list item. */
  endItem?: React.ReactNode;
  /** Optionally, specify a function to be called when the list item is clicked. If not present, the list item will not be clickable. */
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLLIElement>;
  /** The content of the component. */
  children: React.ReactNode;
}

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      id,
      startItem,
      endItem,
      onClick,
      children,
      className,
      style,
      'data-testid': dataTestId,
      ...props
    },
    ref
  ) => {
    const { variant, divider } = useContext(ListThemeContext) || {};
    const listItemId = useId(id);
    const [cssPrefix] = useCSSPrefix();
    const isDefaultVariant = variant === 'default' || variant === undefined;
    const dividerClass =
      isDefaultVariant && divider
        ? `${cssPrefix}-list-item-divider-${divider}`
        : '';
    const onItemClick = isDefaultVariant ? onClick : undefined;
    return (
      <li
        {...props}
        ref={ref}
        id={listItemId}
        style={style}
        className={clsx([className, `${cssPrefix}-list-item`])}
        data-testid={dataTestId}
      >
        <ListItemContainer onClick={onItemClick}>
          <ListItemRow>
            {isDefaultVariant && (
              <ListItemCol
                className={clsx([`${cssPrefix}-list-item-start`, dividerClass])}
              >
                {startItem}
              </ListItemCol>
            )}
            <ListItemCol className={clsx([dividerClass])}>
              {children}
            </ListItemCol>
            {isDefaultVariant && (
              <ListItemCol
                className={clsx([`${cssPrefix}-list-item-end`, dividerClass])}
              >
                {endItem}
              </ListItemCol>
            )}
          </ListItemRow>
        </ListItemContainer>
      </li>
    );
  }
);
