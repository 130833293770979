import { announce } from '@react-aria/live-announcer';
import pluralize from '../internal/utils/pluralize';

/**
 * The search dropdown has features that aria doesn't have a direct analog to, so we're manually
 * announcing some actions.
 * TODO: Localize these strings.
 */

/**
 * Announce when an option is selected. This is necessary because by default VoiceOver won't announce
 * this because we're using an html input as the search field, but our selected value is a separate element.
 * @param optionText
 */
export function announceSelect(optionTextValue: string) {
  announce(`${optionTextValue}, selected`);
}

/**
 * VoiceOver won't announce our selected value when the input is first focused for the above reason,
 * so we need to manually do it.
 */
export function announceFirstFocus(
  label: string,
  value: string,
  readOnly: boolean
) {
  const parts = [
    label,
    value,
    !readOnly && 'type to search',
    readOnly && 'read only',
  ];
  announce(parts.filter((part) => !!part).join(', '));
}

/**
 * Announce when we get search results back from the API.
 */
export function announceSearchSuccess(numOptions: number) {
  announce(
    `${numOptions} ${pluralize(numOptions, 'option', 'options')} available`
  );
}
