import React from 'react';
import clsx from 'clsx';
import { CommonProps } from '../internal/interfaces';

export interface RowProps
  extends React.HTMLAttributes<HTMLElement>,
    CommonProps {
  /**
   * Optionally specify the underlying HTML element
   */
  as?: React.ElementType;
}

export const Row = React.forwardRef<HTMLElement, RowProps>(
  (
    {
      className,
      as: Component = 'div',
      'data-testid': dataTestId = 'row',
      ...props
    },
    ref
  ) => {
    return (
      <Component
        data-testid={dataTestId}
        ref={ref}
        {...props}
        className={clsx(['row', className])}
      />
    );
  }
);
