import { useEffect, useRef } from 'react';
import { InfiniteScroll } from '../utils/InfiniteScroll';

export interface UseInfiniteScroll {
  checkScroll: () => void;
  scrollerRef: (node: HTMLElement | null) => void;
}

/**
 * Listens for scroll and resize events and calls `onNearBottom()` if the scroll position is close to the bottom.
 * Callers must attach the returned `scrollerRef` to a scrollable div like `<div ref={scrollerRef}>`.
 * @param onNearBottom Will be called when the user scrolls near the bottom of the element.
 * @param maxDistanceFromBottom In pixels, defaults to 200. Defines the threshold when `onNearBottom` will be called.
 * @returns
 *  - `checkScroll` Can be called to manually trigger a check. This may be necessary if you have dynamically
 * sized content.
 *  - `scrollerRef` Must be attached to as a ref on scrollable div.
 */
export function useInfiniteScroll(
  searchResults: any[],
  onNearBottom: () => void,
  maxDistanceFromBottom: number = 300
): UseInfiniteScroll {
  // We're using a class instead of a hook for InfiniteScroll because it makes
  // the callbacks much easier to work with.
  const scrollRef = useRef<InfiniteScroll>();
  if (!scrollRef.current) {
    scrollRef.current = new InfiniteScroll(onNearBottom, maxDistanceFromBottom);
  }
  const scroll = scrollRef.current;

  // Keep its properties updated.
  scroll.onNearBottom = onNearBottom;
  scroll.maxDistanceFromBottom = maxDistanceFromBottom;

  // If our search results don't fill the height, then we'll never get scroll events.
  // So, we need to check once more whenever the results change.
  useEffect(() => {
    scroll.check();
  }, [searchResults, searchResults.length]);

  // Cleanup
  useEffect(() => {
    return scroll.destroy;
  }, []);

  return {
    checkScroll: scroll.check,
    scrollerRef: scroll.scrollerRef,
  };
}
