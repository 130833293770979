import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import './Tag.scss';
import { useCSSPrefix } from '../internal/hooks';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';

export type TagColor =
  | 'default'
  | 'tan'
  | 'pink'
  | 'peach'
  | 'orange'
  | 'darkGreen'
  | 'secondaryBlue'
  | 'purple';

export type TagVariant = 'filled' | 'outlined';

export type TagRef = HTMLButtonElement | HTMLDivElement;

const defaultElement = 'div';
export interface TagProps extends React.HTMLAttributes<TagRef>, CommonProps {
  /**
   * Specify the variant of the tag component.
   */
  variant?: TagVariant;
  /**
   * Specify a color for the tag component.
   */
  color?: TagColor;
  /**
   * Specify the label text of the tag component.
   */
  label?: string;
  /**
   * Optionally, specify a function to be called when the close button is clicked. If not present, the close button will be hidden and the tag cannot be dismissed.
   */
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Optionally, specify props for the close button.
   */
  closeButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  /**
   * Optionally, specify a function to be called when the tag is clicked. If not present, the tag will not be clickable.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Specify the component to be displayed on the left hand side of the tag. This should be an avatar or an icon.
   */
  leadingElement?: ReactNode;
  /**
   * Specify whether the tag should be disabled, or not
   *
   * @default false
   */
  disabled?: boolean;
}

export const Tag: PolymorphicForwardRefExoticComponent<
  TagProps,
  typeof defaultElement
> = React.forwardRef(function Tag<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    className,
    color = 'default',
    variant = 'filled',
    leadingElement,
    label,
    disabled,
    closeButtonProps,
    onClose,
    onClick,
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<TagProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = onClick
    ? 'button'
    : as || defaultElement;

  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);

  const { 'aria-label': closeButtonAriaLabel = 'close' } =
    closeButtonProps || {};

  return (
    <Component
      {...rest}
      ref={ref}
      id={id}
      className={clsx([
        `${cssPrefix}-tag`,
        disabled && 'tag-disabled',
        `tag-${color}`,
        variant === 'outlined' && `tag-outlined`,
        onClick && `tag-button`,
        className,
      ])}
      onClick={onClick}
      aria-disabled={disabled}
      disabled={Component === 'button' ? disabled : undefined}
      tabIndex={!disabled && onClick ? 0 : -1}
    >
      {leadingElement && (
        <div className="tag-leading-element">{leadingElement}</div>
      )}
      {label && (
        <Typography variant="caption1" className="tag-label">
          {label}
        </Typography>
      )}
      {!!onClose && !onClick && (
        <button
          {...closeButtonProps}
          type="button"
          className="tag-close"
          aria-label={closeButtonAriaLabel}
          data-testid="tag-close-button"
          onClick={onClose}
          disabled={disabled}
        >
          <Icon icon="close" />
        </button>
      )}
    </Component>
  );
});
