import { createContext } from 'react';
import { Sizes } from '../internal/types';

export interface IToggleButtonGroupContext {
  /**
   * Specify the size of the component, from the following list of sizes: sm, md, lg
   *
   * @default 'md'
   */
  size: Sizes;
  /**
   * Specify whether multiple children can be selected at a given time, or not
   *
   * @default false
   */
  disabled: boolean;
  /**
   * Array of all currently selected values
   */
  selected: any;
  /**
   * onClick handler to be fired when a toggleButton is selected
   */
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    toggleButtonValue: any
  ) => void;
}

export const ToggleButtonGroupContext =
  createContext<IToggleButtonGroupContext | null>(null);
