import * as React from 'react';
import clsx from 'clsx';
import { CommonProps } from '../internal/interfaces';
import { DEFAULT_BREAKPOINTS } from '../useBreakpoints';

type NumberAttr =
  | number
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

type ColOrderNumber = number | '1' | '2' | '3' | '4' | '5';
type ColOrder = ColOrderNumber | 'first' | 'last';
type ColSize = boolean | 'auto' | NumberAttr;

export type ColSpec =
  | ColSize
  | { span?: ColSize; offset?: NumberAttr; order?: ColOrder };

export interface ColProps
  extends React.HTMLAttributes<HTMLElement>,
    CommonProps {
  /**
   *  The number of columns to span on extra small devices (<576px)
   *
   *  @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
   */
  xs?: ColSpec;
  /**
   * The number of columns to span on small devices (≥576px)
   *
   * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
   */
  sm?: ColSpec;
  /**
   * The number of columns to span on medium devices (≥768px)
   *
   * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
   */
  md?: ColSpec;
  /**
   * The number of columns to span on large devices (≥992px)
   *
   * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
   */
  lg?: ColSpec;
  /**
   * The number of columns to span on extra large devices (≥1200px)
   *
   * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
   */
  xl?: ColSpec;
  /**
   * The number of columns to span on extra extra large devices (≥1400px)
   *
   * @type {(boolean|"auto"|number|{ span: boolean|"auto"|number, offset: number, order: "first"|"last"|number })}
   */
  xxl?: ColSpec;
  /**
   * Optionally specify the underlying HTML element
   */
  as?: React.ElementType;
}

export interface UseColMetadata {
  as?: React.ElementType;
  prefix: string;
  spans: string[];
}

export function useCol({
  as,
  className,
  ...props
}: ColProps): [any, UseColMetadata] {
  const prefix = 'col';
  const minBreakpoint = 'xs';

  const breakpoints = Object.keys(DEFAULT_BREAKPOINTS);

  const spans: string[] = [];
  const classes: string[] = [];

  const { xs, sm, md, lg, xl, xxl, ...rest } = props;
  const breakpointObj: { [key: string]: ColSpec | undefined } = {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  };

  breakpoints.forEach((brkPoint) => {
    const propValue = breakpointObj[brkPoint];

    let span: ColSize | undefined;
    let offset: NumberAttr | undefined;
    let order: ColOrder | undefined;

    if (typeof propValue === 'object' && propValue != null) {
      ({ span, offset, order } = propValue);
    } else {
      span = propValue;
    }

    const infix = brkPoint !== minBreakpoint ? `-${brkPoint}` : '';

    if (span)
      spans.push(
        span === true ? `${prefix}${infix}` : `${prefix}${infix}-${span}`
      );

    if (order != null) classes.push(`order${infix}-${order}`);
    if (offset != null) classes.push(`offset${infix}-${offset}`);
  });

  return [
    { ...rest, className: clsx([className, ...spans, ...classes]) },
    {
      as,
      prefix,
      spans,
    },
  ];
}

export const Col = React.forwardRef<HTMLElement, ColProps>((props, ref) => {
  const [
    { className, 'data-testid': dataTestId = 'col', ...colProps },
    { prefix, spans },
  ] = useCol(props);
  const { as: Component = 'div' } = props;
  return (
    <Component
      data-testid={dataTestId}
      {...colProps}
      ref={ref}
      className={clsx([!spans.length && prefix, className])}
    />
  );
});
