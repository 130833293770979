import { useMemo } from 'react';

export interface IUseFilteredListOptions {
  /**
   * If `true`, filtering will be case sensitive.
   * @default false
   */
  caseSensitive?: boolean;
}

/**
 * Filters a list by a query. Calls `getText` on each item to get its text value.
 * @returns a filtered version of the `items` list.
 */
export function useFilteredList<T>(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  items: T[] = [],
  query: string,
  getText: (item: T) => string,
  options: IUseFilteredListOptions = {}
): T[] {
  // Cache the text value of each item. This will change much less frequently than our query, if ever.
  const itemTexts: string[] = useMemo(() => {
    if (options.caseSensitive) {
      return items.map(getText);
    }

    return items.map((item) => {
      return getText(item).toLowerCase();
    });
  }, [items]);

  // Return a filtered list
  return useMemo(() => {
    if (!query) return items.slice();

    let actualQuery = query;
    if (!options.caseSensitive) {
      actualQuery = query.toLowerCase();
    }

    // We're just using "includes" for basic searching, which should be good enough for most cases.
    return items.filter((_, i) => {
      return itemTexts[i].includes(actualQuery);
    });
  }, [query, items]);
}
