import React from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import {
  useControlledState,
  useCSSPrefix,
  useForwardedRef,
} from '../internal/hooks';
import { CommonProps } from '../internal/interfaces';
import { BasePopper, BasePopperProps } from '../internal/components/BasePopper';
import './Tooltip.scss';

export interface TooltipProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<
      BasePopperProps,
      | 'show'
      | 'setShow'
      | 'placement'
      | 'showOnElementDelay'
      | 'showOnElementEvents'
    >,
    CommonProps {
  /**
   * Specify the content to display within the Tooltip window
   */
  content: React.ReactNode;
  /**
   * Specify the reference element to which the Tooltip window will attach to
   */
  children: React.ReactElement;
}

export const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      id,
      children,
      className,
      content,
      placement,
      show: showProp,
      showOnElementDelay,
      showOnElementEvents = ['hover'],
      ...rest
    },
    ref
  ): React.ReactElement => {
    const [cssPrefix] = useCSSPrefix();
    const [show, setShow] = useControlledState(false, showProp, 'Tooltip');
    const [referenceElement, setReferenceElement] =
      React.useState<HTMLElement | null>(null);

    const child: React.ReactElement & {
      ref?: React.Ref<TooltipProps> | null;
    } = React.Children.only(children);

    const generatedId = useId(id);
    const tooltipId = `tooltip-${generatedId}`;

    return (
      <>
        {React.cloneElement(child, {
          ref: useForwardedRef(child.ref, setReferenceElement),
          /**
           * conditionally set aria-describedby attribute on reference element when tooltip is showing, will use id if one exists
           * inspect dom: https://getbootstrap.com/docs/4.3/components/tooltips/
           */
          ...(show && {
            'aria-describedby': tooltipId,
          }),
          ...child.props,
        })}

        <BasePopper
          arrow
          show={show}
          placement={placement}
          setShow={setShow as React.Dispatch<React.SetStateAction<boolean>>}
          referenceElement={referenceElement}
          showOnElement={referenceElement}
          showOnElementEvents={showOnElementEvents}
          showOnElementDelay={showOnElementDelay}
          {...rest}
        >
          <div
            id={tooltipId}
            className={clsx([`${cssPrefix}-tooltip`, className])}
            role="tooltip"
            ref={ref}
          >
            {content}
          </div>
        </BasePopper>
      </>
    );
  }
);
