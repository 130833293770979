import React from 'react';
import clsx from 'clsx';
import { useCSSPrefix } from '../internal/hooks';
import './SearchDropdown.scss';

export interface SearchDropdownItem2LineProps
  extends React.ComponentProps<'div'> {
  /**
   * The top line of an item.
   */
  title: string;
  /**
   * The bottom line of an item.
   */
  subtitle: string;
}

export function SearchDropdownItem2Line({
  title,
  subtitle,
  className,
  ...props
}: SearchDropdownItem2LineProps) {
  const [cssPrefix] = useCSSPrefix();

  return (
    <div
      className={clsx(className, `${cssPrefix}-search-dropdown-item-2-line`)}
      {...props}
    >
      <div
        className={`${cssPrefix}-search-dropdown-item-2-line-title`}
        title={title}
      >
        {title}
      </div>
      <div
        className={`${cssPrefix}-search-dropdown-item-2-line-subtitle`}
        title={subtitle}
      >
        {subtitle}
      </div>
    </div>
  );
}
