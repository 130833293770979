import { MutableRefObject, useEffect, useState } from 'react';
import { useWindowDimensions } from './useWindowDimensions';

export const useIsOverflow = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (ref.current) {
      const hasOverflow = ref.current?.scrollWidth > ref.current?.clientWidth;
      setIsOverflow(hasOverflow);
    }
  }, [ref, width, height]);

  return isOverflow;
};
