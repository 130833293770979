/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  forwardRef,
  ReactNode,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
  ComponentPropsWithoutRef,
} from 'react';
import clsx from 'clsx';
import scrollIntoView from 'scroll-into-view-if-needed';
import { useId } from '@reach/auto-id';
import {
  useForwardedRef,
  useCSSPrefix,
  useDescriptiveText,
} from '../internal/hooks';
import {
  useFilteredList,
  IUseFilteredListOptions,
} from '../internal/hooks/useFilteredList';
import { clampNumber as clamp } from '../internal/utils/clamp';
import { FormFieldProps } from '../internal/interfaces';
import { Key } from '../internal/enums';
import { HelperText } from '../internal/components/HelperText';
import { InputLabel } from '../internal/components/InputLabel';
import { BasePopper } from '../internal/components/BasePopper';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';
import { Checkbox, CheckboxProps } from '../Checkbox';
import { Typography } from '../Typography';
import { ButtonRef } from '../Button';
import { Tag } from '../Tag';
import './Multiselect.scss';

export type IOptionId = string | number;

enum Direction {
  Previous = 'Previous',
  Next = 'Next',
}

export interface IFilterOptions<T> extends IUseFilteredListOptions {
  /**
   * Specify a function to extract the text to filter against. If not provided, defaults to using `getOptionText`.
   * @default getOptionText
   */
  getFilterText?: (option: T) => string;
}

export interface MultiselectProps<T = string>
  extends Omit<
      ComponentPropsWithoutRef<'input'>,
      'size' | 'value' | 'onChange'
    >,
    FormFieldProps {
  /**
   * If `true`, closes the list of options after making a selection.
   * @default false
   */
  closeOnSelect?: boolean;
  /**
   * If `true`, displays a checkbox next to the option and enables the ability to select all options.
   * @default false
   */
  showCheckboxes?: boolean;
  /**
   * The maximum number of tags that will be visible when input is not focused. Defaults to `-1` to disable the limit.
   * @default -1
   */
  limitTags?: number;
  /**
   * The list of options from which to choose.
   */
  options: T[];
  /**
   * The selected option(s).
   */
  value: T[];
  /**
   * Callback fired when the value changes.
   */
  onChange: (newValue: T[]) => void;
  /**
   * If supplying a list of objects to `options`, specify a function to extract the option id.
   * @default (option: string) => option
   */
  getOptionId?: (option: T) => IOptionId;
  /**
   * If supplying a list of objects to `options`, specify a function to extract the text of the selected option.
   * This is what will be displayed in the Tag.
   * @default (option: string) => option
   */
  getOptionText?: (option: T) => string;
  /**
   * Optionally, specify a function to determine which option(s) should be disabled.
   */
  getOptionDisabled?: (option: T) => boolean;
  /**
   * Allows for customizing the filtering behavior.
   */
  filterOptions?: IFilterOptions<T>;
  /**
   * Function to customize how the options are rendered in the list.
   */
  renderOption?: (option: T) => ReactNode;
  /**
   * Function to customize the content to display when a search yields no results.
   * @default () => 'No results'
   */
  renderNoResults?: (query: string) => ReactNode;
}

export const Multiselect = forwardRef(function Multiselect<T = string>(
  {
    style,
    className,
    'data-testid': dataTestId,
    id: propsId,
    readOnly = false,
    disabled = false,
    compact = false,
    margin = false,
    required = false,
    hideRequiredStyle = false,
    fullWidth = false,
    label,
    helperText,
    placeholder,
    errorText,
    successText,
    showCheckboxes = false,
    closeOnSelect = false,
    limitTags = -1,
    options = [],
    value = [],
    onChange,
    getOptionId = (option) => option as string,
    getOptionText = (option) => option as string,
    getOptionDisabled = () => false,
    filterOptions = {},
    renderOption,
    renderNoResults = () => 'No results',
    onClick: onClickProp,
    ...otherProps
  }: MultiselectProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [cssPrefix] = useCSSPrefix();
  const id = useId(propsId);
  const { status } = useDescriptiveText(errorText, successText, helperText);

  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [inputText, setInputText] = useState('');
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1);
  const [focusedTagIndex, setFocusedTagIndex] = useState(-1);

  // This state is what forces the popper to update.
  // The value itself is not important, however, when the value changes it will trigger an update.
  const [updatePopper, setUpdatePopper] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const listboxRef = useRef<HTMLUListElement>(null);
  const highlightedOptionId = useRef<string | undefined>();

  const { getFilterText, caseSensitive } = filterOptions;

  // NOTE: filteredOptions can also contain the whole list (i.e. if the inputText is "")
  const filteredOptions = useFilteredList(
    options,
    inputText,
    getFilterText || getOptionText,
    { caseSensitive }
  );

  const labelId = `${id}-multiselect-label`;
  const listboxId = `${id}-multiselect-listbox`;

  const disabledOrReadonly = disabled || readOnly;

  // If duplicate values exist, we throw an internal error.
  const hasDuplicateValues = new Set(options).size !== options.length;
  useEffect(() => {
    if (hasDuplicateValues) {
      console.error(
        'CDS: An instance of Multiselect has duplicate strings in the array supplied to the `options` prop. To use duplicate values, supply an array of objects and specify a unique id.'
      );
    }
  }, [options]);

  // Store any locked values on mount
  const initialValue = useRef<T[]>([]);
  useEffect(() => {
    initialValue.current = value.filter((opt) => getOptionDisabled(opt));
  }, []);

  const selectedOptionIDs = useMemo(
    () => new Set<IOptionId>(value.map((option) => getOptionId(option))),
    [options, value]
  );

  // Ignores all disabled options
  const allSelectableOptions = useMemo(
    () => filteredOptions.filter((opt) => !getOptionDisabled(opt)),
    [filteredOptions]
  );

  const areAllAvailableOptionsSelected = useMemo(
    () =>
      showCheckboxes &&
      allSelectableOptions.length !== 0 &&
      allSelectableOptions.every((a) => selectedOptionIDs.has(getOptionId(a))),
    [showCheckboxes, allSelectableOptions, selectedOptionIDs]
  );

  const isUserFiltering =
    filteredOptions.length !== options.length || !!inputText;

  const selectAllText = `Select All ${isUserFiltering ? '(Filtered)' : ''}`;

  const listToRender = showCheckboxes
    ? [selectAllText, ...filteredOptions]
    : filteredOptions;

  // ---------------- Handlers and Callbacks ----------------

  function focusInput() {
    inputRef.current?.focus();
  }

  function clearInput() {
    setInputText('');
  }

  function open() {
    focusInput();
    setIsOpen(true);
  }

  function close(keepFocus = false) {
    if (keepFocus) {
      focusInput();
    }
    setIsOpen(false);
    updateHighlightedOptionIndex({ index: -1 });
  }

  function onToggleOpen(e: React.MouseEvent<HTMLButtonElement>) {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation();
    isOpen ? close(true) : open();
  }

  function forcePopperUpdate() {
    setUpdatePopper((prev) => !prev);
  }

  function selectOption(incomingOption: T) {
    clearInput();
    onChange([...value, incomingOption]);
    forcePopperUpdate();
  }

  function unselectOption(incomingOption: T) {
    clearInput();
    const newValue = value.filter(
      (option) => getOptionId(option) !== getOptionId(incomingOption)
    );
    onChange(newValue);
    forcePopperUpdate();
  }

  /**
   * Given two arrays: A and B, returns their symmetric difference.
   * @param arrA
   * @param arrB
   * @returns An array of elements that are in A or B, but not in A and B. In other words, the union of A and B minus their intersection.
   */
  function getSymmetricDifference(arrA: T[], arrB: T[]): T[] {
    return arrA
      .filter((a) => !arrB.some((b) => getOptionId(b) === getOptionId(a)))
      .concat(
        arrB.filter((b) => !arrA.some((a) => getOptionId(a) === getOptionId(b)))
      );
  }

  function selectAll() {
    const diff = getSymmetricDifference(value, allSelectableOptions);

    // When `value` and `allSelectableOptions` have no common elements then `diff` will
    // also include `value`. We handle this edge case by filtering those elements out.
    const finalDiff = diff.filter(
      (a) => !value.some((b) => getOptionId(a) === getOptionId(b))
    );
    const newValue = [...value, ...finalDiff];

    clearInput();
    onChange(newValue);
    forcePopperUpdate();
  }

  function unselectAll() {
    const newValue = getSymmetricDifference(value, allSelectableOptions);

    clearInput();
    onChange(newValue);
    forcePopperUpdate();
  }

  function handleRemoveAll(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    clearInput();
    onChange([...initialValue.current]);
    forcePopperUpdate();
  }

  function openIfSafe() {
    if (!disabledOrReadonly && !isOpen) {
      open();
    }
  }

  function handleContentClick() {
    focusInput();
    openIfSafe();
  }

  function handleInputTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setInputText(e.target.value);
    openIfSafe();
    updateHighlightedOptionIndex({ index: -1 });
  }

  function handleInputClick(e: React.MouseEvent<HTMLInputElement>) {
    openIfSafe();

    // We're hijacking the native input's onClick behavior so we need to
    // ensure we're also calling it incase the consumer specifies a callback.
    onClickProp?.(e);
  }

  function handleInputFocus() {
    setIsFocused(true);
  }

  function handleInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    const elementReceivingFocus = e.relatedTarget;
    const isTagCloseButton =
      elementReceivingFocus?.getAttribute('data-testid') === 'tag-close-button';

    if (isTagCloseButton) return;
    close();
    setIsFocused(false);
  }

  function handleTagBlur(e: React.FocusEvent<HTMLButtonElement>) {
    const elementReceivingFocus = e.relatedTarget;
    const isTagCloseButton =
      elementReceivingFocus?.getAttribute('data-testid') === 'tag-close-button';

    if (isTagCloseButton) return;
    setFocusedTagIndex(-1);
    setIsFocused(false);
  }

  function afterClose() {
    clearInput();
  }

  function onMouseDown(
    e: React.MouseEvent<HTMLDivElement | ButtonRef | HTMLLabelElement>,
    blurInput = false
  ) {
    if (disabledOrReadonly || blurInput) return;

    // prevent input blur
    e.preventDefault();
  }

  function handleOptionMouseOver(e: React.MouseEvent<HTMLLIElement>) {
    updateHighlightedOptionIndex({
      index: Number(e.currentTarget.getAttribute('data-option-index')),
      reason: 'mouse',
    });
  }

  // ---------------------- Navigation ----------------------

  function updateHighlightedOptionIndex({
    index,
    reason = 'none',
  }: {
    index: number;
    reason?: 'keyboard' | 'mouse' | 'none';
  }) {
    setHighlightedOptionIndex(index);

    const option = listboxRef.current?.querySelector(
      `[data-option-index="${index}"]`
    );
    highlightedOptionId.current = option?.id;

    // Scroll to the selected option.
    if (index !== -1 && option && reason === 'keyboard') {
      scrollIntoView(option, {
        scrollMode: 'if-needed',
        block: 'nearest',
        boundary: option.parentElement,
      });
    }
  }

  // Ignores disabled options by returning the next appropriate index
  function validateOptionIndex(index: number, direction: Direction): number {
    if (!listboxRef.current || index === -1) return -1;

    let nextFocus = index;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // Remove the highlighting if you navigate out of range
      if (
        (direction === Direction.Next && nextFocus === listToRender.length) ||
        (direction === Direction.Previous && nextFocus === -1)
      ) {
        return -1;
      }

      const optionNode = listboxRef.current.querySelector(
        `[data-option-index="${nextFocus}"]`
      );
      const nextFocusDisabled =
        !optionNode || optionNode.getAttribute('aria-disabled') === 'true';

      if (nextFocusDisabled) {
        nextFocus += direction === Direction.Next ? 1 : -1;
      } else {
        return nextFocus;
      }
    }
  }

  function changeHighlightedOptionIndex(diff: number) {
    const maxIndex = listToRender.length - 1;
    const num = highlightedOptionIndex + diff;

    // Ensures highlighted index stays within bounds
    const newIndex = clamp(num, 0, maxIndex);

    const direction = diff > 0 ? Direction.Next : Direction.Previous;
    const nextIndex = validateOptionIndex(newIndex, direction);

    updateHighlightedOptionIndex({ index: nextIndex, reason: 'keyboard' });
  }

  // Select the highlighted option, if there is one.
  function toggleOptionSelection() {
    let indexToUse = highlightedOptionIndex;

    // Offset highlighted index to account for "Select All" option
    if (showCheckboxes) {
      indexToUse -= 1;
    }

    const option = filteredOptions?.[indexToUse];

    if (option) {
      if (selectedOptionIDs.has(getOptionId(option))) {
        unselectOption(option);
      } else {
        selectOption(option);
      }
    }
  }

  function handleSelectingOptionWithKey() {
    if (highlightedOptionIndex === -1) return;

    const optionNode = listboxRef.current?.querySelector(
      `[data-option-index="${highlightedOptionIndex}"]`
    );
    const isSelectAll = optionNode?.getAttribute('role') === 'checkbox';

    if (isSelectAll) {
      areAllAvailableOptionsSelected ? unselectAll() : selectAll();
    } else {
      toggleOptionSelection();
    }
  }

  const focusTag = (tagIndexToFocus: number) => {
    if (tagIndexToFocus === -1) {
      focusInput();
    } else {
      const tag = referenceElement?.querySelector(
        `[data-tag-index="${tagIndexToFocus}"]`
      );
      const tagCloseButton = tag?.lastChild as HTMLElement | null | undefined;
      tagCloseButton?.focus();
    }
  };

  function validateTagIndex(index: number, direction: Direction) {
    if (!referenceElement || index === -1) {
      return -1;
    }

    let nextFocus = index;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // Remove focus if you navigate out of range
      if (
        (direction === Direction.Next && nextFocus === value.length) ||
        (direction === Direction.Previous && nextFocus === -1)
      ) {
        return -1;
      }

      const tagNode = referenceElement.querySelector(
        `[data-tag-index="${nextFocus}"]`
      );

      if (!tagNode || tagNode.getAttribute('aria-disabled') === 'true') {
        nextFocus += direction === Direction.Next ? 1 : -1;
      } else {
        return nextFocus;
      }
    }
  }

  function changeFocusedTagIndex(diff: number) {
    if (!inputText) {
      close();
    }

    let nextTagIndex = focusedTagIndex;
    const direction = diff > 0 ? Direction.Next : Direction.Previous;

    if (focusedTagIndex === -1) {
      if (!inputText && direction === Direction.Previous) {
        nextTagIndex = value.length - 1;
      }
    } else {
      nextTagIndex += diff;

      if (nextTagIndex < 0) {
        nextTagIndex = 0;
      }

      if (nextTagIndex === value.length) {
        nextTagIndex = -1;
      }
    }

    nextTagIndex = validateTagIndex(nextTagIndex, direction);

    setFocusedTagIndex(nextTagIndex);
    focusTag(nextTagIndex);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.isDefaultPrevented()) return;

    if (
      focusedTagIndex !== -1 &&
      [Key.ArrowLeft, Key.ArrowRight].indexOf(e.key as Key) === -1
    ) {
      // Resets the focusTagIndex if other keys pressed
      setFocusedTagIndex(-1);
      focusTag(-1);
    }

    const preventDefaults = () => {
      e.preventDefault();
      e.stopPropagation();
    };

    switch (e.key) {
      case Key.ArrowDown:
      case Key.ArrowUp:
        preventDefaults();
        if (isOpen) {
          changeHighlightedOptionIndex(e.key === Key.ArrowDown ? 1 : -1);
        } else {
          openIfSafe();
        }
        break;
      case Key.ArrowLeft:
      case Key.ArrowRight:
        if (!inputText) {
          preventDefaults();
          changeFocusedTagIndex(e.key === Key.ArrowLeft ? -1 : 1);
        }
        break;
      case Key.Enter:
        preventDefaults();
        if (isOpen) {
          handleSelectingOptionWithKey();
        } else {
          openIfSafe();
        }
        break;
      case Key.Space:
        if (!inputText) {
          preventDefaults();
          if (isOpen) {
            handleSelectingOptionWithKey();
          } else {
            openIfSafe();
          }
        }
        break;
      case Key.Escape:
        preventDefaults();
        if (isOpen) {
          close();
        }
        break;
      case Key.Backspace:
        if (
          !disabledOrReadonly &&
          !inputText &&
          value.length > 0 &&
          value.length !== initialValue.current.length
        ) {
          const index =
            focusedTagIndex === -1 ? value.length - 1 : focusedTagIndex;
          const newValue = value.slice();
          newValue.splice(index, 1);
          onChange(newValue);
          forcePopperUpdate();
        }
        break;
      default:
    }
  }

  let listOfTags = value;
  let numSurplusTags = -1;
  let showBadge = false;

  if (limitTags > -1) {
    numSurplusTags = value.length - limitTags;
    if (!isFocused && numSurplusTags > 0) {
      listOfTags = value.slice(0, limitTags);
      showBadge = true;
    }
  }

  return (
    <div
      id={id}
      data-testid={dataTestId}
      style={style}
      aria-owns={isOpen ? listboxId : undefined}
      className={clsx(
        className,
        `${cssPrefix}-multiselect-root`,
        margin && `margin`,
        fullWidth && `full-width`
      )}
    >
      {label && (
        <InputLabel
          id={labelId}
          htmlFor={id}
          disabled={disabled}
          hideRequiredStyle={hideRequiredStyle}
          required={required}
        >
          {label}
        </InputLabel>
      )}
      <div
        className={clsx(
          `${cssPrefix}-multiselect-content-wrapper`,
          compact && `compact`,
          disabled && 'disabled',
          readOnly && 'read-only',
          status
        )}
        onKeyDown={handleKeyDown}
        onClick={handleContentClick}
        onMouseDown={onMouseDown}
        ref={setReferenceElement}
      >
        <div
          className={clsx(
            `${cssPrefix}-multiselect-content`,
            value.length > 0 && 'limit-width'
          )}
        >
          {listOfTags.map((opt, index) => {
            const isLocked = getOptionDisabled(opt);
            const isDisabled = disabledOrReadonly || isLocked;
            const tagLabel = getOptionText(opt);
            return (
              <Tag
                data-testid="tag"
                data-tag-index={index}
                key={getOptionId(opt)}
                label={tagLabel}
                variant="filled"
                disabled={isDisabled}
                closeButtonProps={{
                  onMouseDown,
                  tabIndex: -1,
                  'aria-label': `Remove selection, ${tagLabel}`,
                  onBlur: handleTagBlur,
                }}
                onClose={
                  !isDisabled
                    ? (e) => {
                        e.stopPropagation();
                        unselectOption(opt);
                      }
                    : undefined
                }
              />
            );
          })}
          {showBadge && <OverflowCounter count={numSurplusTags} />}
          <input
            {...otherProps}
            aria-activedescendant={
              isOpen ? highlightedOptionId.current : undefined
            }
            aria-autocomplete="list"
            aria-controls={isOpen ? listboxId : undefined}
            aria-expanded={isOpen}
            aria-labelledby={labelId}
            aria-required={required}
            autoCapitalize="none"
            autoComplete="off"
            className={`${cssPrefix}-multiselect-input`}
            disabled={disabled}
            onClick={handleInputClick}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputTextChange}
            readOnly={readOnly}
            ref={useForwardedRef(ref, inputRef)}
            role="combobox"
            spellCheck="false"
            type="text"
            placeholder={placeholder}
            value={inputText}
          />
        </div>
        <div className={`${cssPrefix}-multiselect-adornments`}>
          {value.length > 0 && (
            <IconButton
              size="xs"
              disabled={disabledOrReadonly}
              aria-label="Clear"
              tabIndex={-1}
              onMouseDown={onMouseDown}
              onClick={handleRemoveAll}
            >
              <Icon icon="close" />
            </IconButton>
          )}
          <IconButton
            size="xs"
            className={clsx('open-button', isOpen && 'rotate')}
            disabled={disabledOrReadonly}
            aria-label="Open"
            tabIndex={-1}
            onMouseDown={onMouseDown}
            onClick={onToggleOpen}
          >
            <Icon icon="chevron-down" />
          </IconButton>
        </div>
      </div>
      <HelperText
        errorText={errorText}
        successText={successText}
        helperText={helperText}
      />
      <BasePopper
        sameWidthAsReferenceElement
        shouldUpdate={updatePopper}
        show={isOpen}
        setShow={setIsOpen}
        placement="bottom-start"
        referenceElement={referenceElement}
        afterClose={afterClose}
      >
        <div onMouseDown={(e) => onMouseDown(e, closeOnSelect)}>
          {filteredOptions.length === 0 && (
            <div className={`${cssPrefix}-multiselect-no-results`}>
              {renderNoResults(inputText)}
            </div>
          )}
          {filteredOptions.length > 0 && (
            <ul
              id={listboxId}
              ref={listboxRef}
              role="listbox"
              aria-labelledby={labelId}
              className={`${cssPrefix}-multiselect-list`}
            >
              {listToRender.map((option, index) => {
                const isSelectAll = (opt: string | T): opt is string => {
                  return showCheckboxes && index === 0;
                };

                const commonOptionProps = {
                  id: `${id}-option-${index}`,
                  'data-option-index': index,
                  onMouseOver: handleOptionMouseOver,
                };

                const commonCheckboxProps: CheckboxProps = {
                  readOnly: true,
                  'aria-hidden': true,
                  tabIndex: -1,
                  labelProps: {
                    onClick: onMouseDown,
                  },
                };

                let optionProps = {};
                let checkboxProps = {};

                // Are we rendering the "Select All" option?
                const isSelectAllOption = isSelectAll(option);
                if (isSelectAllOption) {
                  optionProps = {
                    className: clsx(`${cssPrefix}-multiselect-list-item`, {
                      highlighted: index === highlightedOptionIndex,
                    }),
                    key: 'select all',
                    role: 'checkbox',
                    'aria-checked': areAllAvailableOptionsSelected,
                    'aria-disabled': false,
                    onClick: () => {
                      areAllAvailableOptionsSelected
                        ? unselectAll()
                        : selectAll();
                    },
                  };

                  checkboxProps = {
                    'data-testid': 'multiselect-checkbox-select-all',
                    indeterminate:
                      selectedOptionIDs.size > 0 &&
                      !areAllAvailableOptionsSelected,
                    checked: areAllAvailableOptionsSelected,
                  };
                } else {
                  // Otherwise render a standard option
                  const tempKey = getOptionId(option);
                  const tempIsDisabled = getOptionDisabled(option);
                  const tempIsSelected = selectedOptionIDs.has(tempKey);

                  optionProps = {
                    className: clsx(`${cssPrefix}-multiselect-list-item`, {
                      selected: tempIsSelected,
                      highlighted: index === highlightedOptionIndex,
                    }),
                    key: tempKey,
                    role: 'option',
                    tabIndex: -1,
                    'aria-disabled': getOptionDisabled(option),
                    'aria-selected': tempIsSelected,
                    onClick: () => {
                      tempIsSelected
                        ? unselectOption(option)
                        : selectOption(option);
                    },
                  };

                  checkboxProps = {
                    'data-testid': `multiselect-checkbox-${tempKey}`,
                    checked: tempIsSelected,
                    disabled: tempIsDisabled,
                  };
                }

                const isStandardOption = !isSelectAllOption && !renderOption;

                return (
                  <li {...commonOptionProps} {...optionProps}>
                    <div className="list-item-content">
                      {showCheckboxes && (
                        <Checkbox {...commonCheckboxProps} {...checkboxProps} />
                      )}
                      {isSelectAllOption || isStandardOption ? (
                        <OptionText bold={isSelectAllOption}>
                          {isSelectAllOption ? option : getOptionText(option)}
                        </OptionText>
                      ) : (
                        renderOption?.(option)
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </BasePopper>
    </div>
  );
}) as <T>(
  props: MultiselectProps<T> & React.RefAttributes<HTMLInputElement>
) => ReactElement | null;

const OverflowCounter = ({ count }: { count: number }) => {
  const [cssPrefix] = useCSSPrefix();

  return (
    <div className={`${cssPrefix}-multiselect-badge`}>
      <Typography variant="body1">{`+${count}`}</Typography>
    </div>
  );
};

const OptionText = ({
  bold,
  children,
}: {
  bold: boolean;
  children: ReactNode;
}) => {
  const [cssPrefix] = useCSSPrefix();

  return (
    <Typography
      variant="body1"
      fontWeight={bold ? 'medium' : undefined}
      className={`${cssPrefix}-multiselect-list-item-text`}
    >
      {children}
    </Typography>
  );
};
